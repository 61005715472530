import {
  USERS_COINS_FETCH,
  USERS_COINS_SUCCESS,
  USERS_COINS_ERROR,
  USERS_COINS_PROFILE_FETCH,
  USERS_COINS_PROFILE_SUCCESS,
  USERS_COINS_PROFILE_ERROR,
  USERS_GRATITUDES_FETCH,
  USERS_GRATITUDES_SUCCESS,
  USERS_GRATITUDES_ERROR,
  USERS_IDEAS_FETCH,
  USERS_IDEAS_SUCCESS,
  USERS_IDEAS_ERROR,
  USERS_BATTLES_FETCH,
  USERS_BATTLES_SUCCESS,
  USERS_BATTLES_ERROR,
} from "../actions/users";
import {
  GetPlayersCoinRating,
  GetPlayersGratitudeRating,
  GetPlayersProfileCoinRating,
  GetPlayersIdeaCoinRating,
  GetPlayersBattleRating,
} from "@/api/Rating";

const state = {
  usersCoins: [],
  usersCoinsOverall: [],
  usersCoinsProfile: [],
  usersCoinsProfileOverall: [],
  countCoins: 0,
  statusCoins: "",
  statusCoinsProfile: "",

  usersGratitudes: [],
  usersGratitudesOverall: [],
  countGratitudes: 0,
  statusGratitudes: "",

  usersIdeas: [],
  usersIdeasOverall: [],
  countIdeas: 0,
  statusIdeas: "",

  usersBattles: [],
  usersBattlesOverall: [],
  countBattles: 0,
  statusBattles: "",
};

const getters = {
  getUsersCoins: (state) => state.usersCoins,
  getUsersCoinsOverall: (state) => state.usersCoinsOverall,
  getUsersCoinsProfile: (state) => state.usersCoinsProfile,
  getUsersCoinsProfileOverall: (state) => state.usersCoinsProfileOverall,
  getUsersCoinsStatus: (state) => state.statusCoins,
  getUsersCoinsProfileStatus: (state) => state.statusCoinsProfile,
  getUsersGratitudes: (state) => state.usersGratitudes,
  getUsersGratitudesOverall: (state) => state.usersGratitudesOverall,
  getUsersGratitudesStatus: (state) => state.statusGratitudes,
  getUsersIdeas: (state) => state.usersIdeas,
  getUsersIdeasOverall: (state) => state.usersIdeasOverall,
  getUsersIdeasStatus: (state) => state.statusIdeas,
  getUsersBattles: (state) => state.usersBattles,
  getUsersBattlesOverall: (state) => state.usersBattlesOverall,
  getUsersBattlesStatus: (state) => state.statusBattles,
};

const actions = {
  [USERS_COINS_FETCH]: ({ commit }) => {
    const p1 = new Promise((resolve) => {
      commit(USERS_COINS_FETCH);
      GetPlayersCoinRating({ pageSize: 10000, pageNum: 0, isOverall: false })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_COINS_ERROR);
        });
    });

    const p2 = new Promise((resolve) => {
      commit(USERS_COINS_FETCH);
      GetPlayersCoinRating({ pageSize: 10000, pageNum: 0, isOverall: true })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_COINS_ERROR);
        });
    });

    return Promise.all([p1, p2]).then((values) => {
      commit(USERS_COINS_SUCCESS, values);
    });
  },
  [USERS_COINS_PROFILE_FETCH]: ({ commit }) => {
    const p1 = new Promise((resolve) => {
      commit(USERS_COINS_PROFILE_FETCH);
      GetPlayersProfileCoinRating({
        pageSize: 10000,
        pageNum: 0,
        isOverall: false,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_COINS_PROFILE_ERROR);
        });
    });

    const p2 = new Promise((resolve) => {
      commit(USERS_COINS_PROFILE_FETCH);
      GetPlayersProfileCoinRating({
        pageSize: 10000,
        pageNum: 0,
        isOverall: true,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_COINS_PROFILE_ERROR);
        });
    });

    return Promise.all([p1, p2]).then((values) => {
      commit(USERS_COINS_PROFILE_SUCCESS, values);
    });
  },
  [USERS_GRATITUDES_FETCH]: ({ commit }) => {
    const p1 = new Promise((resolve) => {
      commit(USERS_GRATITUDES_FETCH);
      GetPlayersGratitudeRating({
        pageSize: 10000,
        pageNum: 0,
        isOverall: false,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_GRATITUDES_ERROR);
        });
    });

    const p2 = new Promise((resolve) => {
      commit(USERS_GRATITUDES_FETCH);
      GetPlayersGratitudeRating({
        pageSize: 10000,
        pageNum: 0,
        isOverall: true,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_GRATITUDES_ERROR);
        });
    });

    return Promise.all([p1, p2]).then((values) => {
      commit(USERS_GRATITUDES_SUCCESS, values);
    });
  },
  [USERS_IDEAS_FETCH]: ({ commit }) => {
    const p1 = new Promise((resolve) => {
      commit(USERS_GRATITUDES_FETCH);
      GetPlayersIdeaCoinRating({
        pageSize: 10000,
        pageNum: 0,
        isOverall: false,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_IDEAS_ERROR);
        });
    });

    const p2 = new Promise((resolve) => {
      commit(USERS_IDEAS_FETCH);
      GetPlayersIdeaCoinRating({
        pageSize: 10000,
        pageNum: 0,
        isOverall: true,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_IDEAS_ERROR);
        });
    });

    return Promise.all([p1, p2]).then((values) => {
      commit(USERS_IDEAS_SUCCESS, values);
    });
  },
  [USERS_BATTLES_FETCH]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(USERS_BATTLES_FETCH);
      GetPlayersBattleRating({
        pageSize: 10000,
        pageNum: 0,
      })
        .then((resp) => {
          commit(USERS_BATTLES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(() => {
          commit(USERS_BATTLES_ERROR);
        });
    });
  },
};

const mutations = {
  [USERS_COINS_FETCH]: (state) => {
    state.statusCoins = "loading";
  },
  [USERS_COINS_SUCCESS]: (state, values) => {
    state.statusCoins = "success";
    state.usersCoins = values[0].players;
    state.usersCoinsOverall = values[1].players;
    // state.countCoins = data.overallCount;

    state.usersCoins.forEach((user) => {
      user.search = user.name.concat(user.nickname || "");
    });
    state.usersCoinsOverall.forEach((user) => {
      user.search = user.name.concat(user.nickname || "");
    });
  },
  [USERS_COINS_ERROR]: (state) => {
    state.statusCoins = "error";
  },
  [USERS_COINS_PROFILE_FETCH]: (state) => {
    state.statusCoinsProfile = "loading";
  },
  [USERS_COINS_PROFILE_SUCCESS]: (state, values) => {
    state.statusCoinsProfile = "success";
    state.usersCoinsProfile = values[0];
    state.usersCoinsProfileOverall = values[1];
    // state.countCoins = data.overallCount;

    // state.usersCoinsProfile.forEach((user) => {
    //   user.search = user.name.concat(user.nickname || "");
    // });
    // state.usersCoinsProfileOverall.forEach((user) => {
    //   user.search = user.name.concat(user.nickname || "");
    // });
  },
  [USERS_COINS_PROFILE_ERROR]: (state) => {
    state.statusCoinsProfile = "error";
  },
  [USERS_GRATITUDES_FETCH]: (state) => {
    state.statusGratitudes = "loading";
  },
  [USERS_GRATITUDES_SUCCESS]: (state, values) => {
    state.statusGratitudes = "success";
    state.usersGratitudes = values[0].players;
    state.usersGratitudesOverall = values[1].players;

    state.usersGratitudes.forEach((user) => {
      user.search = user.name.concat(user.nickname || "");
    });
    state.usersGratitudesOverall.forEach((user) => {
      user.search = user.name.concat(user.nickname || "");
    });
  },
  [USERS_GRATITUDES_ERROR]: (state) => {
    state.statusGratitudes = "error";
  },
  [USERS_IDEAS_FETCH]: (state) => {
    state.statusIdeas = "loading";
  },
  [USERS_IDEAS_SUCCESS]: (state, values) => {
    state.statusIdeas = "success";
    state.usersIdeas = values[0].players;
    state.usersIdeasOverall = values[1].players;

    state.usersIdeas.forEach((user) => {
      user.search = user.name.concat(user.nickname || "");
    });
    state.usersIdeasOverall.forEach((user) => {
      user.search = user.name.concat(user.nickname || "");
    });
  },
  [USERS_IDEAS_ERROR]: (state) => {
    state.statusIdeas = "error";
  },

  [USERS_BATTLES_FETCH]: (state) => {
    state.statusBattles = "loading";
  },
  [USERS_BATTLES_SUCCESS]: (state, data) => {
    state.statusBattles = "success";
    state.usersBattles = data.players;
    state.usersBattlesOverall = data.overallCount;

    state.usersBattles.forEach((user) => {
      user.search = user.name.concat(user.nickname || "");
    });
  },
  [USERS_BATTLES_ERROR]: (state) => {
    state.statusBattles = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
