import { render, staticRenderFns } from "./ModalLegend.vue?vue&type=template&id=6e536e59&scoped=true&"
import script from "./ModalLegend.vue?vue&type=script&lang=js&"
export * from "./ModalLegend.vue?vue&type=script&lang=js&"
import style0 from "./ModalLegend.vue?vue&type=style&index=0&id=6e536e59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e536e59",
  null
  
)

export default component.exports