<template>
  <modal
    class="modal-overlay default"
    :name="name"
    :scrollable="true"
    height="auto"
  >
    <ModalLayout :name="name" type="default reduce">
      <div class="modal-form">
        <image-cropper
          :width="300"
          :height="300"
          url="/administrator/DomainUsers/UploadProfilePhoto"
          :params="{ userId: 1 }"
          field="image"
          lang-type="ru"
          @close="close"
          @crop-upload-success="cropSuccess"
        />

        <button
          v-if="getProfile.photo"
          @click="onDeletePhoto"
          class="button button-default button-delete"
        >
          Удалить
        </button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";
import ImageCropper from "@/components/ImageCropper";
import { DeleteProfilePhoto } from "@/api/Player";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { ModalLayout, ImageCropper },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  data() {
    return {
      name: "upload-photo",
    };
  },
  methods: {
    ...mapMutations(["USER_DELETE_PHOTO"]),
    getPhoto() {
      // GetProfilePhoto({ userId: this.user.id }).then(response => {
      this.image = "/static/img/game/1.jpg";
      // });
    },
    handleRemovePhoto() {
      alert("Удалить");
    },
    cropSuccess() {
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      // this.image = resData.files.image;
      this.getPhoto();
    },
    close() {
      this.$modal.hide(this.name);
      // this.imagecropperShow = false;
    },
    onDeletePhoto() {
      DeleteProfilePhoto()
        .then(() => {
          this.USER_DELETE_PHOTO();
        })
        .catch(() => {
          this.$modal.show("message", {
            message: "Произошла ошибка, попробуйте позже",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v--modal-overlay {
  background: rgba(51, 51, 51, 0.9) !important;
}

.modal-form {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 300px;
}

.button-delete {
  position: absolute;
  left: 0;
  bottom: 20px;
}
</style>
