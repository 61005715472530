import { TEAM_FETCH, TEAM_SUCCESS, TEAM_ERROR } from "../actions/team";
import { GetTeamById } from "@/api/Team";

const state = {
  team: {},
  status: "",
};

const getters = {
  getTeam: (state) => state.team,
  teamStatus: (state) => state.status,
};

const actions = {
  [TEAM_FETCH]: ({ commit }, teamID) => {
    return new Promise((resolve) => {
      commit(TEAM_FETCH);
      GetTeamById({ teamId: teamID })
        .then((resp) => {
          // добавляем место по рейтингу
          if (resp?.data?.teamPlayers) {
            const ratingIsland = resp.data.teamPlayers
              .map((e) => e)
              .sort((a, b) => {
                return (
                  b.calculatedCoinsCurrentIsland -
                  a.calculatedCoinsCurrentIsland
                );
              });

            const ratingTotal = resp.data.teamPlayers
              .map((e) => e)
              .sort((a, b) => {
                return b.playerCoins - a.playerCoins;
              });

            resp.data.teamPlayers.forEach((e) => {
              ratingIsland.forEach((el, index) => {
                if (e.id === el.id) {
                  e.ratingIsland = index + 1;
                }
              });
              ratingTotal.forEach((el, index) => {
                if (e.id === el.id) {
                  e.ratingTotal = index + 1;
                }
              });
            });
          }
          commit(TEAM_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(TEAM_ERROR);
        });
    });
  },
};

const mutations = {
  [TEAM_FETCH]: (state) => {
    state.status = "loading";
  },
  [TEAM_SUCCESS]: (state, data) => {
    state.status = "success";
    state.team = data;
  },
  [TEAM_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
