import catalog from "./catalog";
import product from "./product";
import orders from "./orders";
import cart from "./cart";

const modules = {
  catalog,
  product,
  orders,
  cart,
};

export default {
  modules,
};
