<template>
  <div class="pane">
    <div class="pane__inner">
      <!-- <div class="coin">
        <img src="@/assets/img/pic2/coin.svg" width="15" height="15" alt="" />
      </div>
      <div class="col__text">
        <span class="margin_left">Баланс</span>
        <div class="col col__balance">{{ COINS }}:</div>
      </div>
      <div class="col col__available-coins">
        <span class="field-text">Доступно</span
        >{{ getProfile.coinBalance | priceFilter }}
      </div>
      <div class="col col__gratefullness">
        <span class="field-text">Благодарностей</span
        >{{ getProfile.canSendCoins | priceFilter }}
      </div> -->
      <div class="col col__coins">
        <img src="@/assets/img/pic2/coin.svg" width="15" height="15" alt="" />
        GPB-coins:<b>{{ getProfile.coinBalance | priceFilter }}</b>
      </div>
      <router-link
        class="col col__notify"
        :to="{
          name: 'notify',
        }"
        ><span class="quantity" v-if="getNewNotifyCount">{{
          getNewNotifyCount
        }}</span></router-link
      >
      <br />
      <router-link
        class="col col__text col__profile"
        :to="{
          name: 'profile',
        }"
      >
        <div>
          <img v-if="getProfile.photo" class="avatar" :src="userPhoto" alt="" />
          <div v-else class="avatar-default">
            <img
              src="@/assets/img/pic2/avatar-default-small.png"
              width="44"
              height="44"
              alt=""
            />
          </div>
        </div>
        <span>Личный кабинет</span></router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { islandList } from "@/data/game";
import { MarkNotification } from "@/api/PlayerNotifications";
import { getBaseURL } from "@/utils";

export default {
  computed: {
    ...mapGetters([
      "getProfile",
      "getNewNotify",
      "getNewNotifyCount",
      "getProfileCurrentIsland",
    ]),
    userPhoto() {
      return getBaseURL() + this.getProfile.photo;
    },
  },
  watch: {
    $route(to) {
      if (to.name === "home") {
        this.checkGameNotify();
      }
    },
  },
  created() {
    this.USER_FETCH_NOTIFY().then(() => {
      if (this.$route.name === "home") {
        this.checkGameNotify();
      }
    });
  },
  methods: {
    ...mapActions(["USER_FETCH_NOTIFY"]),
    readNotify(id) {
      MarkNotification({ notificationId: id }).then(() => {
        this.USER_FETCH_NOTIFY();
      });
    },
    checkGameNotify() {
      setTimeout(() => {
        this.getNewNotify.forEach((notify) => {
          // показываем видео из прошедшего острова
          // NewIslandOpened
          if (notify.notificationType === 1 && notify.isFresh) {
            let islandIndex = notify.islandIndex;

            // не показываем на первом острове
            if (islandIndex > 0) {
              let name =
                islandList[islandIndex - 1] && islandList[islandIndex - 1].name;
              this.$modal.show("islandAfter", {
                name: name,
              });
              this.readNotify(notify.id);
            }
          }

          // сообщение от капитана
          // CaptainsQuestReceived
          if (notify.notificationType === 14 && notify.isFresh) {
            this.$modal.show("message", {
              message: "Смирно! Вы получили задание от капитана команды.",
              textButton: "Перейти",
              cb: () => {
                if (notify.islandIndex >= 0) {
                  islandList.forEach((island, index) => {
                    if (index === notify.islandIndex) {
                      this.$router.push({
                        name: "island",
                        params: { name: island.name },
                      });
                      return;
                    }
                  });
                }
              },
            });
            this.readNotify(notify.id);
          }
        });
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.pane {
  // background: url(~@/assets/img/page/bg/nav.png) 0 50% no-repeat;
  // height: 70px;
  // width: 703px;
  &__inner {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 20px;
  }
}
.col {
  display: flex;
  &__text {
    color: #fff;
    text-decoration: none;
  }
  &__balance {
    width: 83px;
    margin-left: 5px;
  }
  &__available-coins {
    height: 45px;
    margin-left: 25px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    background-image: url(~@/assets/img/icon/bracket.svg);
    background-repeat: no-repeat;
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      height: 45px;
      width: 12px;
      background-image: url(~@/assets/img/icon/bracket.svg);
      background-repeat: no-repeat;
      transform: rotate(180deg);
      content: "";
    }
  }
  &__gratefullness {
    height: 45px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    background-image: url(~@/assets/img/icon/bracket.svg);
    background-repeat: no-repeat;
    position: relative;
    padding: 0 12px;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      height: 45px;
      width: 12px;
      background-image: url(~@/assets/img/icon/bracket.svg);
      background-repeat: no-repeat;
      transform: rotate(180deg);
      content: "";
    }
  }
  &__coins {
    height: 45px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-size: 17px;
    color: #fff;
    background-image: url(~@/assets/img/icon/bracket.svg);
    background-repeat: no-repeat;
    position: relative;
    padding: 0 20px;
    img {
      margin-right: 4px;
      display: block;
      margin-bottom: -2px;
    }
    b {
      font-weight: 700;
      font-size: 19px;
      color: #00ffff;
      margin-left: 4px;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      height: 45px;
      width: 12px;
      background-image: url(~@/assets/img/icon/bracket.svg);
      background-repeat: no-repeat;
      transform: rotate(180deg);
      content: "";
    }
  }
  &__notify {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 44px;
    margin-left: 25px;
    text-decoration: none;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='23' viewBox='0 0 21 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7839 20.2605c0 .597-.2395 1.1696-.666 1.5918a2.2854 2.2854 0 0 1-1.6079.6593 2.2854 2.2854 0 0 1-1.6079-.6593 2.2399 2.2399 0 0 1-.666-1.5918h4.5478ZM10.51 0c.3016 0 .5908.1186.804.3297a1.12 1.12 0 0 1 .333.7959v1.2156c3.2289.5403 5.6847 3.3205 5.6847 6.6634v6.7535l3.4109 3.3768H.2775l3.4108-3.3768V9.0046c0-3.343 2.4558-6.1231 5.6848-6.6634V1.1256a1.12 1.12 0 0 1 .333-.796A1.1428 1.1428 0 0 1 10.51 0Z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #fff;
    img {
      display: block;
    }
  }
  &__profile {
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
    > div {
      margin-right: 10px;
    }
    &:hover,
    &.router-link-active {
      span {
        opacity: 1;
      }
    }
    // &.router-link-active {
    //   span {
    //     font-weight: bold;
    //   }
    // }
  }
}
.margin_left {
  margin-left: -15px;
}
.field-text {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ade5d9;
}
.quantity {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #00ffff;
  font-weight: 700;
  font-size: 9px;
  text-align: center;
  color: #333;
  border-radius: 16px;
}
.avatar {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: block;
  &-default {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 44px;
    // border: 1px solid #fff;
    img {
      display: block;
    }
  }
}
.coin {
  width: 16px;
  margin-left: 48px;
  display: flex;
  align-items: bottom;
  img {
    width: 100%;
    height: auto;
    margin-top: 16px;
  }
}
</style>
