import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// polyfill for vue-select (IE11)
import "core-js/features/array/includes";

import VModal from "vue-js-modal";
import vSelect from "vue-select";
import Loading from "@/components/Loading";
import VueForm from "vue-form";

import { format, addWeeks } from "date-fns";
import { ru } from "date-fns/locale";

import analitics from "@/utils/analitics";

Vue.config.productionTip = false;

// analitics
analitics();

// global variables
Vue.mixin({
  data() {
    return {
      COINS: "GPB-coins",
      ISLAND_CELLS_AMOUNT: 16,
    };
  },
  methods: {
    weekCoinsFilter(number) {
      const week = store.getters.getGame.currentWeek;
      // если неделя 0, 4, 8 ..., ставим прочерк
      if (week % 4 === 0) {
        return "-";
      }

      if (number >= 0) {
        return number;
      }

      return "-";
    },
    handleError(error) {
      const { status, data } = error;

      // предварительно закрываем ранее открытые
      this.$modal.hide("message");

      if (status === 403) {
        this.$modal.show("message", {
          message: "Доступ запрещён. Недостаточно прав.",
        });
      } else if (status === 401) {
        this.$modal.show("message", {
          message: "Ошибка авторизации.",
        });
      } else if (data) {
        this.$modal.show("message", {
          message: data,
        });
      } else {
        this.$modal.show("message", {
          message: "Произошла ошибка. Попробуйте позже!",
        });
      }
    },
  },
});

// components
Vue.component("v-select", vSelect);
Vue.component("Loading", Loading);
Vue.use(VModal).use(VueForm);

// filters
Vue.filter("priceFilter", function (number) {
  if (number > 0) {
    if (Number.isInteger(number)) {
      return number.toLocaleString("ru-RU");
    }
    return number;
  }

  return "0";
});

Vue.filter("coinsFilter", function (number) {
  if (number >= 0) {
    return number;
  }

  return "-";
});

Vue.filter("mathRound", function (number) {
  if (number > 0) {
    return Math.round(number);
  }

  return "0";
});

Vue.filter("mathRoundToDecimal", function (number) {
  if (number > 0) {
    return +number.toFixed(1);
  }

  return number;
});

Vue.filter("formatDate", function (date) {
  if (!date) return "";
  return format(new Date(date), "dd MMMM yyyy", { locale: ru });
});

Vue.filter("formatDate2", function (date) {
  if (!date) return "";
  return format(new Date(date), "dd.MM.yyyy", { locale: ru });
});

Vue.filter("formatDateTime", function (date) {
  if (!date) return "";
  return format(new Date(date), "HH:mm, dd MMMM yyyy", { locale: ru });
});

Vue.filter("formatDateTime2", function (date) {
  if (!date) return "";
  return format(new Date(date), "dd.MM.yyyy HH:mm", { locale: ru });
});

Vue.filter("formatTimeRange", function (week) {
  if (!store.getters.getGame) return "";

  const startDate = new Date(store.getters.getGame.startDate);
  const start = addWeeks(startDate, week);
  const end = addWeeks(startDate, week + 1);

  return `с ${format(start, "dd.MM")} по ${format(end, "dd.MM")}`;
});
Vue.filter("formatTimeRange2", function (week) {
  if (!store.getters.getGame) return "";

  const startDate = new Date(store.getters.getGame.startDate);
  const start = addWeeks(startDate, week);
  const end = addWeeks(startDate, week + 1);

  return `${format(start, "dd.MM")} - ${format(end, "dd.MM")}`;
});

Vue.filter("functionalGroupFilter", function (groupId) {
  const groupMap = {
    0: "Не определена",
    1: "Аккредитация и мониторинг",
    2: "Андеррайтинг клиента",
    3: "Андеррайтинг ОН",
    4: "Андеррайтинг клиента нестандарт, ИУ",
    5: "Андеррайтинг ОН нестандарт, ИУ",
    6: "УДЛ, АЭ, ФА",
  };
  return groupMap[groupId];
});

Vue.filter("functionalTaskStatus", function (id) {
  const statusMap = {
    0: "Запланировано",
    1: "Получено",
    2: "На модерации",
    3: "Выполнено",
    4: "Неудачно",
    5: "Участник не принял задание",
  };
  return statusMap[id];
});

Vue.filter("formatTeamQuest", function (id) {
  const statusMap = {
    0: "Назначена", // Created
    1: "В работе", // InProgress
    2: "На модерации", // OnModeration
    3: "Выполнена", // Succeeded
    4: "Не выполнена", // Failed
  };
  return statusMap[id] || "";
});

Vue.filter("formatIdea", function (id) {
  const statusMap = {
    0: "Черновик",
    1: "На модерации",
    2: "На доработке",
    3: "Удалена",
    4: "Отклонена",
    5: "На комиссии",
    6: "Оценена",
  };
  return statusMap[id] || "";
});

Vue.filter("formatSeasonIdeas", function (id) {
  const statusMap = {
    0: "Запланирован",
    1: "Подача",
    2: "Проверка",
    3: "Голосование",
    4: "Результаты",
    5: "Не проводится",
  };
  return statusMap[id] || "";
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
