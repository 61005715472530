import {
  CATALOG_FETCH,
  CATALOG_SUCCESS,
  CATALOG_ERROR,
} from "../../actions/shop/catalog";
import { GetPrizes } from "@/api/OrderPlayer";

const state = {
  catalog: [],
  status: "",
};

const getters = {
  getCatalog: (state) => state.catalog,
  getCatalogStatus: (state) => state.status,
};

const actions = {
  [CATALOG_FETCH]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(CATALOG_FETCH);
      GetPrizes()
        .then((resp) => {
          commit(CATALOG_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(CATALOG_ERROR);
        });
    });
  },
};

const mutations = {
  [CATALOG_FETCH]: (state) => {
    state.status = "loading";
  },
  [CATALOG_SUCCESS]: (state, data) => {
    state.status = "success";
    state.catalog = data;
  },
  [CATALOG_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
