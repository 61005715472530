import axios from "axios";
import { getBaseURL } from "@/utils";

const instance = axios.create({
  baseURL: getBaseURL(true),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export function authenticate(query) {
  return instance({
    url: "/Authentication/authenticate",
    method: "post",
    params: query,
  });
}

export function ldapAuthenticate(query) {
  return instance({
    url: "/Authentication/ldapAuthenticate",
    method: "post",
    params: query,
  });
}
