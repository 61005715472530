<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name">
      <div class="modal-pic">
        <img
          v-if="islandID === 'asiris'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/asiris.png"
          width="279"
          height="276"
          alt=""
        />
        <img
          v-if="islandID === 'timeless'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/timeless.png"
          width="327"
          height="323"
          alt=""
        />
        <img
          v-if="islandID === 'nibiru'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/nibiru.png"
          width="314"
          height="334"
          alt=""
        />
        <img
          v-if="islandID === 'kriptos'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/kriptos.png"
          width="335"
          height="287"
          alt=""
        />
        <img
          v-if="islandID === 'pandoria'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/pandoria.png"
          width="310"
          height="348"
          alt=""
        />
        <img
          v-if="islandID === 'tatuum'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/tatuum.png"
          width="291"
          height="330"
          alt=""
        />
        <img
          v-if="islandID === 'krediti'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/krediti.png"
          width="249"
          height="250"
          alt=""
        />
        <img
          v-if="islandID === 'giperiya'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/giperiya.png"
          width="424"
          height="327"
          alt=""
        />
        <img
          v-if="islandID === 'morroviya'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/morroviya.png"
          width="304"
          height="311"
          alt=""
        />
        <img
          v-if="islandID === 'obliviya'"
          class="pic"
          src="~@/assets/img/pic2/previews/modal/obliviya.png"
          width="202"
          height="199"
          alt=""
        />
      </div>
      <div
        class="modal-desc"
        v-html="islands[islandID] && islands[islandID].text"
      ></div>
      <div class="modal-foot">
        <button
          v-if="islandID === 'obliviya'"
          @click="$router.push({ name: 'store' })"
          class="button-default button_big modal-button"
        >
          Перейти
        </button>
        <button
          v-else
          @click="$modal.hide(name)"
          class="button-default button_big modal-button"
        >
          ОК
        </button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "islandBefore",
      islandID: null,
      islands: {
        asiris: {
          text: "Вперед, через тернии к&nbsp;звездам! Ваше путешествие начинается на&nbsp;Асирисе. Планета долгое время находилась на&nbsp;темной стороне вселенной и&nbsp;теперь, после долгих лет радиационного воздействия, населена мутантами, контакт с&nbsp;которыми установить непросто. Задача&nbsp;&mdash; найти Камень Безумия. Оружие, которое остановит Краноса, должно обладать силой за&nbsp;гранью разума! Кстати, здесь невозможно находиться без скафандра и&nbsp;надо действовать очень быстро, зато найденный камень и&nbsp;GPB-coins станут отличной наградой за&nbsp;достигнутую цель!",
        },
        timeless: {
          text: "Таймлес&nbsp;&mdash; это планета, на&nbsp;которой время всегда относительно. Из-за встречи с&nbsp;космической аномалией поверхность планеты покрыта межвременными кратерами через которые можно телепортироваться в&nbsp;будущее или прошлое. Эпохи на&nbsp;этой планете перемешаны, и&nbsp;иногда бывает сложно определить, в&nbsp;каком времени мы&nbsp;находимся. <br>Камень Времени позволит нам приступить к&nbsp;созданию оружия, которое даст надежду в&nbsp;битве с&nbsp;Краносом! Задача найти камень и&nbsp;вернуться к&nbsp;кораблю в&nbsp;ту&nbsp;же временную прямую!",
        },
        nibiru: {
          text: "Как вам удалось найти координаты!? Нибиру&nbsp;&mdash; труднодоступная планета, попасть в&nbsp;которую можно через зеркальное отражение пространства, преломив линию времени. Нибирийцы много веков назад разбили большинство пространственных зеркал, из-за чего все на&nbsp;планете теперь постоянно блуждают по&nbsp;отражениям в&nbsp;зеркальных осколках. Не&nbsp;заблудитесь в&nbsp;поисках Камня Отражения, нам нужно оружие, которое обернет силу Краноса против него самого!",
        },
        kriptos: {
          text: "Будьте осторожны! Криптос&nbsp;&mdash; планета логики и&nbsp;правил. Это единственная рукотворная планета во&nbsp;вселенной, созданная по&nbsp;плану и&nbsp;чертежам. Формулы, формуляры и&nbsp;предписания. На&nbsp;этой планете одно неосторожное слово может привести к&nbsp;схлопыванию сингулярности&nbsp;&mdash; мир вокруг начинает просто разваливаться на&nbsp;части, как сыпется таблица эксель от&nbsp;неверно введенного значения! То, что мы&nbsp;должны унести отсюда&nbsp;&mdash; Камень Логики, который позволит просчитывать атаки Краноса и&nbsp;противостоять&nbsp;им! Ищем камень и&nbsp;ретируемся!",
        },
        pandoria: {
          text: "Думайте позитивно! Благодаря мыслящему центру, который с&nbsp;древнейших времен живет в&nbsp;ядре этой планеты, Пандориа отражает опасения и&nbsp;энергетические посылы своих обитателей. Старейшина Пандории оказался пугливым и&nbsp;опасливым правителем, и&nbsp;самые скверные сценарии Пандории вырвались наружу. Теперь это планета, на&nbsp;которой правят страхи, но&nbsp;вам нечего бояться&nbsp;&mdash; у&nbsp;вас есть великая цель! Найдите Камень Помыслов, который усилит оружие в&nbsp;руках отважного воина, а&nbsp;после вас ждут новые задания и&nbsp;GPB-coins!",
        },
        tatuum: {
          text: "Планета расположена на&nbsp;пересечении космических траекторий большинства торговых кораблей, так что стратегия существования ее&nbsp;жителей всегда была прагматичной, а&nbsp;ее&nbsp;жители любили не&nbsp;просто показать, кто тут умнее всех, но&nbsp;и&nbsp;хорошо заработать на&nbsp;космическом трафике. В&nbsp;их&nbsp;планах не&nbsp;было войн и&nbsp;порабощения остальных миров. Они лишь стремились к&nbsp;заработку и&nbsp;превосходству! Найти камень Превосходства непросто, а&nbsp;забрать может лишь тот, кого Татуумы сочтут достойным. Зато после инсталляции камня Молот станет втрое мощнее!",
        },
        krediti: {
          text: "Планета попала в&nbsp;метеоритный поток, который разрушил великую цивилизацию, создававшуюся годами. После уничтожения всех прогрессивных достижений планета оказалась банкротом, а&nbsp;здешние жители превратились в&nbsp;нон-конформистов, которые живут по&nbsp;своим правилам в&nbsp;примитивных условиях. Будем надеяться, они давно забыли про Камень Бессмыслия. Не&nbsp;ясно, это он&nbsp;влияет на&nbsp;жителей, или просто так совпало, но&nbsp;в&nbsp;оружии Камень Бессмыслия добавляет дерзости и&nbsp;шансов на&nbsp;победу! Раздобыть артефакт и&nbsp;GPB-coins тут будет не&nbsp;самой простой задачей, но&nbsp;вы&nbsp;справитесь, ведь у&nbsp;вас за&nbsp;спиной уже большая часть пути, опыт и&nbsp;достижения!",
        },
        giperiya: {
          text: "Планета высоких скоростей находится в&nbsp;другом измерении времени, Камень Скорости не&nbsp;случайно покоится в&nbsp;ее&nbsp;недрах. Обладатель оружия с&nbsp;таким камнем обретет контроль над временем, что позволит обрести контроль и&nbsp;над схваткой! Проблема здесь в&nbsp;том, что минуты, месяцы и&nbsp;годы бегут слишком быстро. Гиперианцы адаптировались к&nbsp;этому, но&nbsp;гипервремя губительно для большинства видов вселенной, так что камень нужно найти быстро! Зато вы&nbsp;сможете оторваться от&nbsp;соперников и&nbsp;получить больше всех GPB-coins.",
        },
        morroviya: {
          text: "Самая дальняя планета от&nbsp;всех солнц, находится на&nbsp;краю вселенной и&nbsp;практически не&nbsp;поддается силе притяжения. Чудо, что она вообще еще держится на&nbsp;орбите. Морровия&nbsp;&mdash; планета ветров. Здесь давно нет жизни. К&nbsp;сожалению, камень Морровии&nbsp;&mdash; это камень Гибели, предающий воинам бесстрашие. Его обязательно нужно уравновешивать другими камнями, иначе ветер на&nbsp;поверхности планеты не&nbsp;утихает, превращая в&nbsp;камни всё на&nbsp;своем пути. Не&nbsp;упустите возможность собрать все спрятанные здесь GPB-coins!",
        },
        obliviya: {
          text: "Обливия... Наша планета, центр цивилизации, дом Камня Мудрости и&nbsp;самое прекрасное место в&nbsp;космосе. Камень мудрости уравновесит все камни и&nbsp;сделает оружие пригодным к&nbsp;использованию. Уже много столетий Обливия, или &laquo;Земля&raquo;, как называют ее&nbsp;местные жители, объединяет и&nbsp;примиряет все остальные планеты и&nbsp;их&nbsp;обитателей. Нам повезло, что наша база именно здесь.<br>Здесь, в&nbsp;зале славы каждый может выбрать себе заслуженную награду. GPB-coins можно обменять на&nbsp;нужные и&nbsp;памятные вещи, уникальные подарки и&nbsp;интересные артефакты!",
        },
      },
    };
  },
  methods: {
    beforeOpen(event) {
      this.islandID = event.params.name;
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.pic {
  margin-top: -30px;
  margin-bottom: 35px;
}
</style>
