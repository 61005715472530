<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <ModalLayout :name="name">
      <div class="modal-task">
        <div class="modal-task__name">Тест</div>
        <div class="modal-task__title">{{ data.title }}</div>
        <div class="modal-task__date">
          Получено: {{ data.acceptedDate | formatDateTime2 }}
        </div>
        <div class="modal-task__group">
          <div class="modal-task__cell">
            <span>Награда за успешное выполнение</span><br />
            <strong class="coin">
              <span v-if="data.threeReward >= 0 && data.maxReward"
                >от {{ data.threeReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else-if="data.fourReward >= 0 && data.maxReward"
                >от {{ data.fourReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else>{{ data.maxReward | priceFilter }}</span>
              <img
                src="@/assets/img/pic2/coin.svg"
                width="15"
                height="15"
                alt=""
              />
              <span>{{ COINS }}</span></strong
            >
          </div>
          <div class="modal-task__cell">
            <span>Выполнить до:</span><br />
            <strong
              ><span>{{
                formatDateEnd(data.dateToComplete) | formatDate2
              }}</span></strong
            >
          </div>
        </div>
        <div class="modal-task__body">
          <vue-form :state="formstate" @submit.prevent="onSubmit">
            <div class="question-list">
              <div
                class="question-item"
                v-for="(question, questionIndex) in data.questions"
                :key="questionIndex"
              >
                <div class="question-wrapper">
                  <span class="question-number">{{ questionIndex + 1 }}.</span>
                  <p class="modal-text-question modal-question">
                    {{ question.questionName }}
                  </p>
                </div>
                <!-- <p>{{ question.questionDescription }}</p> -->
                <validate>
                  <select
                    :name="'answers_' + questionIndex"
                    required
                    v-model="question.answer"
                    style="display: none"
                  >
                    <option :value="null">Choose...</option>
                    <option
                      v-for="(answer, index) in question.answers"
                      :key="index"
                      :value="index"
                    >
                      {{ answer }}
                    </option>
                  </select>
                  <button
                    type="button"
                    class="modal-question answer-wrapper"
                    v-for="(answer, index) in question.answers"
                    :key="index"
                    :class="{ selected: question.answer === index }"
                    @click="onSelectAnswer(question, index)"
                  >
                    <span
                      :class="{ selected: question.answer === index }"
                      class="radio-button radio-button-selected"
                    ></span
                    ><span
                      :class="{ selected: question.answer === index }"
                      class="answer bold"
                      >{{ answer }}</span
                    >
                  </button>
                  <field-messages
                    :name="'answers_' + questionIndex"
                    show="$submitted"
                  >
                    <div class="error" slot="required">
                      Выберите вариант ответа
                    </div>
                  </field-messages>
                </validate>
              </div>
            </div>
            <button
              :disabled="
                formstate.$submittedState && formstate.$submittedState.$valid
              "
              type="submit"
              class="button button-default button_big modal-task__button"
            >
              Отправить
            </button>
          </vue-form>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import { mapActions } from "vuex";
import ModalLayout from "@/layout/ModalLayout.vue";
import { StartTestQuest, SaveTestAnswers } from "@/api/PlayerQuests";
import { addDay } from "@/utils";

const tempDefault = {
  questId: null,
  answers: [],
};

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      isBold: false,
      name: "test",
      questId: null,
      data: {},
      formstate: {},
      temp: JSON.parse(JSON.stringify(tempDefault)),
    };
  },
  methods: {
    ...mapActions(["USER_FETCH"]),
    beforeOpen(event) {
      if (event.params) {
        this.questId = event.params.questId;
        this.fetchData();
      } else {
        this.$modal.hide(this.name);
        this.$modal.show("message", {
          message: "Не передан questId",
        });
      }
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    beforeClose() {
      this.resetForm();
      this.$emit("close");
    },
    // убираем ответы null
    // т.к. с файла грузятся все 6 вариантов ответов
    normalizeAnswers(questions) {
      return questions.map((question) => {
        question.answers = question.answers.filter((e) => e !== null);
        return question;
      });
    },
    fetchData() {
      StartTestQuest({ questId: this.questId })
        .then((resp) => {
          this.data = resp.data;
          this.data.questions = this.normalizeAnswers(this.data.questions);
        })
        .catch((err) => {
          this.$modal.hide(this.name);
          this.$modal.show("message", {
            message: err ? err.data : "Произошла ошибка. Попробуйте позже!",
          });
        });
    },
    resetForm() {
      this.formstate._reset();
      this.temp = JSON.parse(JSON.stringify(tempDefault)); // copy obj
    },
    onSelectAnswer(question, index) {
      this.$set(question, "answer", index);
    },
    onSubmit() {
      if (this.formstate.$invalid) {
        return;
      }

      this.temp.answers = [];

      this.temp.questId = this.data.id;
      this.data.questions.forEach((item) => {
        const question = {
          questionId: item.id,
          answer: item.answer,
        };

        this.temp.answers.push(question);
      });

      SaveTestAnswers(this.temp)
        .then((resp) => {
          let status = resp.data.state;
          let message;

          if (status === 3) {
            message = "Звезды говорят, это верный ответ!";
          } else if (status === 4) {
            message = "Увы, звезды не на вашей стороне!";
          }

          if (message) {
            this.$modal.show("message", {
              message: message,
            });
          }

          this.USER_FETCH();

          this.$modal.hide(this.name);
          this.resetForm();
        })
        .catch((err) => {
          this.formstate._reset();
          this.$modal.show("message", {
            message: err ? err.data : "Произошла ошибка. Попробуйте позже!",
          });
        });
    },
    formatDateEnd(date) {
      return date ? addDay(new Date(date), -1) : date;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  .question-wrapper {
    display: flex;
    padding: 0 20px;
  }
  .answer-wrapper {
    display: flex;
    outline: none;
    background: none;
    border: none;
    width: 100%;
    padding: 5px 20px;
    margin-top: 10px;
    position: relative;
    &:hover {
      background-color: rgba(0, 255, 254, 0.15);
    }
  }
  .modal-text-question {
    font-family: $fontRafale;
    font-size: 19px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 10px;
  }
  .question-number {
    font-family: $fontRafale;
    font-size: 19px;
    color: #ffffff;
    margin-right: 4px;
  }
  .button-margin-top {
    margin-top: 40px;
  }
}

.radio-button {
  position: absolute;
  border: 2px solid #00ffff;
  border-radius: 50%;
  outline: none;
  top: 0;
  margin-top: 7px;
  width: 20px;
  height: 20px;
  background: none;
}

.selected {
  &.bold {
    font-weight: normal;
  }
  &.radio-button-selected {
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background: #00ffff;
      box-shadow: 0px 0px 4px #2dffff;
      margin: -5px;
      border-radius: 10px;
      content: "";
    }
  }
}
.answer {
  font-family: $fontRafale;
  font-size: 19px;
  color: #95e5eb;
  margin-left: 32px;
}

.question {
  &-item {
    position: relative;
    margin-top: 40px;
    background: rgba(0, 255, 254, 0.15);
    border-left: 4px solid #00ffff;
    border-right: 4px solid #00ffff;
    padding: 20px 0;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 0 0, 0 100%, 100% 0, 100% 100%;
      content: "";
    }
  }
}
</style>
