import request from "@/utils/request";

export function GetOwnNotifications(query) {
  return request({
    url: "/api/Player/PlayerNotifications/GetOwnNotifications",
    method: "get",
    params: query,
  });
}

export function GetOwnUnreadNotifications(query) {
  return request({
    url: "/api/Player/PlayerNotifications/GetOwnUnreadNotifications",
    method: "get",
    params: query,
  });
}

export function GetUnreadNotificationsCount(query) {
  return request({
    url: "/api/Player/PlayerNotifications/GetUnreadNotificationsCount",
    method: "get",
    params: query,
  });
}

export function MarkNotification(query) {
  return request({
    url: "/api/Player/PlayerNotifications/MarkNotification",
    method: "post",
    params: query,
  });
}

export function MarkAllNotifications(query) {
  return request({
    url: "/api/Player/PlayerNotifications/MarkAllNotifications",
    method: "post",
    params: query,
  });
}