import {
  BATTLE_FETCH,
  BATTLE_UPDATE,
  BATTLE_SUCCESS,
  BATTLE_ERROR,
  BATTLE_NO_BATTLE,
} from "../actions/battle";
import { GetCurrentBattle } from "@/api/Battles";

const state = {
  battle: {},
  battleStatus: "",
};

const getters = {
  getBattle: (state) => state.battle,
  getBattleStatus: (state) => state.battleStatus,
};

const actions = {
  [BATTLE_FETCH]: ({ commit, state }) => {
    return new Promise((resolve) => {
      if (
        state.battleStatus === "loading" ||
        state.battleStatus === "success"
      ) {
        return;
      }

      commit(BATTLE_FETCH);
      GetCurrentBattle()
        .then((resp) => {
          commit(BATTLE_SUCCESS, resp.data);
          resolve();
        })
        .catch((err) => {
          if (err.data === "Нет активного баттла") {
            commit(BATTLE_NO_BATTLE);
          } else {
            commit(BATTLE_ERROR);
          }
        });
    });
  },
  [BATTLE_UPDATE]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(BATTLE_FETCH);
      GetCurrentBattle()
        .then((resp) => {
          commit(BATTLE_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(BATTLE_ERROR);
        });
    });
  },
};

const mutations = {
  [BATTLE_FETCH]: (state) => {
    state.battleStatus = "loading";
  },
  [BATTLE_SUCCESS]: (state, data) => {
    state.battleStatus = "success";
    state.battle = data;
  },
  [BATTLE_ERROR]: (state) => {
    state.battleStatus = "error";
  },
  [BATTLE_NO_BATTLE]: (state) => {
    state.battleStatus = "no_battle";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
