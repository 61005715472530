import { GAME_FETCH, GAME_SUCCESS, GAME_ERROR } from "../actions/game";
import { GetCurrentGameState } from "@/api/GameState";

const state = {
  game: {},
  status: "",
};

const getters = {
  getGame: (state) => state.game,
  getGameStatus: (state) => state.status,
};

const actions = {
  [GAME_FETCH]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(GAME_FETCH);
      GetCurrentGameState()
        .then((resp) => {
          commit(GAME_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(GAME_ERROR);
        });
    });
  },
};

const mutations = {
  [GAME_FETCH]: (state) => {
    state.status = "loading";
  },
  [GAME_SUCCESS]: (state, data) => {
    state.status = "success";
    state.game = data;
  },
  [GAME_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
