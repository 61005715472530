<template>
  <modal
    class="modal-overlay default"
    :name="name"
    height="auto"
    :scrollable="true"
    :clickToClose="false"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="default">
      <div class="modal-list">
        <div v-show="step === 0" class="modal-item">
          <div class="modal-pic">
            <img class="pic-0" src="~@/assets/img/pics/tutorial/0.png" alt="" />
          </div>
          <div>
            <div class="modal-title">Обучение</div>
            <div class="modal-desc">
              Добро пожаловать в&nbsp;игру! Здесь вы&nbsp;и&nbsp;ваши
              коллеги&nbsp;&mdash; супергерои Дефендеры. По&nbsp;легенде игры
              вам предстоит принять участие в&nbsp;великой миссии Совета
              Старейшин Обливии: найти все Камни величия и&nbsp;помочь построить
              Мегамолот&nbsp;&mdash; оружие, которое поможет победить злодея
              Краноса. В&nbsp;вашем распоряжении находятся Фалконы&nbsp;&mdash;
              корабли, способные передвигаться между мирами.
            </div>
          </div>
          <div class="modal-foot">
            <button
              @click="next"
              class="button-default button_big modal-button"
            >
              Начать обучение
            </button>
          </div>
        </div>
        <div v-show="step === 1" class="modal-item">
          <div class="modal-pic">
            <img class="pic-1" src="~@/assets/img/pics/tutorial/1.png" alt="" />
          </div>
          <div class="modal-desc">
            Перед вами навигатор Дефендера с&nbsp;секретной картой планет нашего
            мира. <br />
            Ваша задача&nbsp;&mdash; исследовать планеты, находить
            и&nbsp;выполнять задания.
            <br />
            За&nbsp;это вы&nbsp;будете получать знаки отличия и&nbsp;GPB-coins.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 2" class="modal-item">
          <div class="modal-pic">
            <img class="pic-2" src="~@/assets/img/pics/tutorial/2.png" alt="" />
          </div>
          <div class="modal-desc">
            Ваша первая миссия на&nbsp;планете Асирис. Заходите на&nbsp;планету,
            <br />
            нажимайте на&nbsp;ячейки, находите в&nbsp;них задания
            и&nbsp;выполняйте&nbsp;их.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 3" class="modal-item">
          <div class="modal-pic">
            <img class="pic-3" src="~@/assets/img/pics/tutorial/3.png" alt="" />
          </div>
          <div class="modal-desc">
            В&nbsp;каждой ячейке спрятаны гермокапсулы с&nbsp;заданиями.<br />
            Открывайте капсулы и&nbsp;берите задания.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 4" class="modal-item">
          <div class="modal-pic">
            <img class="pic-4" src="~@/assets/img/pics/tutorial/4.png" alt="" />
          </div>
          <div class="modal-desc">
            Так выглядят капсулы с&nbsp;заданиями. Выбирайте любую
            из&nbsp;них.<br />
            И&nbsp;не&nbsp;останавливайтесь, пока не&nbsp;откроете все ячейки
            планеты. Чем больше <br />
            заданий вы&nbsp;найдете, возьмете и&nbsp;выполните, тем быстрее вы
            продвинетесь в&nbsp;игре.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 5" class="modal-item">
          <div class="modal-pic">
            <img class="pic-5" src="~@/assets/img/pics/tutorial/5.png" alt="" />
          </div>
          <div class="modal-desc">
            GPB-coins&nbsp;&mdash; это игровая валюта. Она начисляется
            за&nbsp;выполнение заданий. <br />
            На&nbsp;нее вы&nbsp;можете купить подарки в&nbsp;магазине, который
            располагается на&nbsp;планете Обливия. Количество заработанных
            GPB-coins влияет на&nbsp;рейтинг вашей команды: чем больше сумма
            заработанных командой монет&nbsp;&mdash; тем выше команда
            в&nbsp;рейтинге.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 6" class="modal-item">
          <div class="modal-pic">
            <img class="pic-6" src="~@/assets/img/pics/tutorial/6.png" alt="" />
          </div>
          <div class="modal-desc">
            Обливия&nbsp;&mdash; домашняя база Дефендеров, где располагается зал
            славы,<br />
            в&nbsp;котором GPB-coins можно обменять на&nbsp;индивидуальные
            призы.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 7" class="modal-item">
          <div class="modal-pic">
            <img class="pic-7" src="~@/assets/img/pics/tutorial/7.png" alt="" />
          </div>
          <div class="modal-desc">
            Обо всех события в&nbsp;игре вы&nbsp;получаете уведомления. <br />
            Они хранятся здесь.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 8" class="modal-item">
          <div class="modal-pic">
            <img class="pic-8" src="~@/assets/img/pics/tutorial/8.png" alt="" />
          </div>
          <div class="modal-desc">
            В&nbsp;личном кабинете хранятся ваши задания, достижения,<br />
            благодарности от&nbsp;коллег и&nbsp;статистика по&nbsp;ключевым
            показателям.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 9" class="modal-item">
          <div class="modal-pic">
            <img class="pic-9" src="~@/assets/img/pics/tutorial/9.png" alt="" />
          </div>
          <div class="modal-desc">
            Все задания вы&nbsp;можете посмотреть в&nbsp;виджете &laquo;Статус
            задач&raquo;.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 10" class="modal-item">
          <div class="modal-pic">
            <img class="pic-10" src="~@/assets/img/pics/tutorial/10.png" alt="" />
          </div>
          <div class="modal-desc">
            В&nbsp;виджете &laquo;Статус задач&raquo; можно посмотреть как
            текущие, <br />
            так и&nbsp;завершенные задачи, а&nbsp;также текущий статус других
            активностей.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 11" class="modal-item">
          <div class="modal-pic">
            <img class="pic-11" src="~@/assets/img/pics/tutorial/11.png" alt="" />
          </div>
          <div class="modal-desc">
            Кроме индивидуальных заданий, вас ждут командные цели. <br />
            Здесь личный результат каждого участника влияет на&nbsp;результат
            <br />
            всей команды. За&nbsp;них вы&nbsp;также можете получать GPB-coins.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 12" class="modal-item">
          <div class="modal-pic">
            <img class="pic-12" src="~@/assets/img/pics/tutorial/12.png" alt="" />
          </div>
          <div class="modal-desc">
            Команды соревнуются по&nbsp;сумме заработанных участниками
            GPB-coins.<br />
            Лучшей командой станет&nbsp;та, которая заработала больше всего
            GPB-coins в&nbsp;командном рейтинге. Участники команды-победителя
            получат суперприз.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 13" class="modal-item">
          <div class="modal-pic">
            <img class="pic-13" src="~@/assets/img/pics/tutorial/13.png" alt="" />
          </div>
          <div class="modal-desc">
            За&nbsp;выполнение различных индивидуальных и&nbsp;групповых
            заданий, <br />
            вы&nbsp;можете получать крутые Ачивки.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 14" class="modal-item">
          <div class="modal-pic">
            <img class="pic-14" src="~@/assets/img/pics/tutorial/14.png" alt="" />
          </div>
          <div class="modal-desc">
            В&nbsp;разделе &laquo;Достижения&raquo; вы&nbsp;можете видеть уже
            полученные награды,
            <br />
            а&nbsp;также прогресс по&nbsp;ещё неполученным. После завершения
            обучения вы можете получить первую Ачивку. Для этого нажмите
            в&nbsp;конце обучения &laquo;Завершить обучение&raquo;
            и&nbsp;возьмите первое задание.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 15" class="modal-item">
          <div class="modal-pic">
            <img class="pic-15" src="~@/assets/img/pics/tutorial/15.png" alt="" />
          </div>
          <div class="modal-desc">
            Отлично, защитник!<br />
            Вы&nbsp;прошли инструктаж и&nbsp;готовы к&nbsp;миссиям.
          </div>
          <div class="modal-foot">
            <button
              @click="startGame"
              class="button-default button_big modal-button"
            >
              Завершить обучение
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import { mapActions } from "vuex";
import ModalLayout from "@/layout/ModalLayout.vue";
import { MarkNotification } from "@/api/PlayerNotifications";
import { CompleteTutorial } from "@/api/Player";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "tutorial",
      step: 0,
      notifyID: undefined,
      hideClose: false,
    };
  },
  methods: {
    ...mapActions(["USER_FETCH_NOTIFY"]),
    back() {
      this.step--;
    },
    next() {
      this.step++;
    },
    beforeOpen(event) {
      this.step = 0;
      if (event.params) {
        this.notifyID = event.params.id;
        this.hideClose = true;
      }
    },
    startGame() {
      this.$modal.hide(this.name);

      if (this.notifyID !== undefined) {
        MarkNotification({ notificationId: this.notifyID }).then(() => {
          this.USER_FETCH_NOTIFY();
        });
      }

      CompleteTutorial();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-item {
    display: grid;
    grid-template-rows: 1fr auto 100px;
    height: calc(70vh - #{$headerHeight});
    min-height: 500px;
    max-height: 650px;
    margin-top: -60px;
  }
  &-pic {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate3d(-50%, -50%, 0);
    }
    .pic {
      &-1 {
        height: 130%;
      }
      &-3 {
        height: 70%;
      }
      &-5 {
        height: auto;
        width: 120%;
      }
      &-6 {
        height: 90%;
      }
      &-7 {
        height: auto;
        width: 120%;
      }
      &-8 {
        height: auto;
        width: 120%;
      }
      &-9 {
        height: 80%;
        margin-top: -25px;
      }
      &-10 {
        margin-top: 25px;
      }
      &-11 {
        height: 80%;
      }
      &-12 {
        height: 80%;
      }
      &-13 {
        height: 75%;
      }
      &-14 {
        height: 95%;
        margin-top: -10px;
      }
      &-15 {
        height: 80%;
        margin-top: 10px;
      }
    }
  }
  &-button {
    margin-left: 7px;
    margin-right: 7px;
  }
}
</style>
