import request from "@/utils/request";

export function GetSeasonState(query) {
  return request({
    url: "/api/Player/Gratitudes/GetSeasonState",
    method: "get",
    params: query,
  });
}

export function GetAvailableGratitudeCoins(query) {
  return request({
    url: "/api/Player/Gratitudes/GetAvailableGratitudeCoins",
    method: "get",
    params: query,
  });
}

export function GratitudePlayer(query) {
  return request({
    url: "/api/Player/Gratitudes/GratitudePlayer",
    method: "post",
    params: query,
  });
}

export function GetOwnGratitudes(query) {
  return request({
    url: "/api/Player/Gratitudes/GetOwnGratitudes",
    method: "get",
    params: query,
  });
}

export function GetAllGratitudes(query) {
  return request({
    url: "/api/Player/Gratitudes/GetAllGratitudes",
    method: "get",
    params: query,
  });
}

export function GetGratitudeById(id) {
  return request({
    url: "/api/Player/Gratitudes/GetGratitudeById",
    method: "get",
    params: { id },
  });
}

export function GetPersonalizedGratitudes(userId) {
  return request({
    url: "/api/Player/Gratitudes/GetPersonalizedGratitudes",
    method: "get",
    params: { userId },
  });
}