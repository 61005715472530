import {
  CART_ADD,
  CART_REMOVE,
  CART_UPDATE,
  CART_CLEAR,
  CART_CHECKOUT,
} from "../../actions/shop/cart";
import { CreateOrder } from "@/api/OrderPlayer";
import Vue from "vue";

const state = {
  cart: [],
};

const getters = {
  getCart: (state) => state.cart,
  getCartQuantity: (state, getters) => {
    return getters.getCart.reduce((total, product) => {
      return total + product.prizeAmount;
    }, 0);
  },
  getCartTotalPrice: (state, getters) => {
    return getters.getCart.reduce((total, product) => {
      return total + product.price * product.prizeAmount;
    }, 0);
  },
};

const actions = {
  [CART_ADD]: ({ commit }, newProduct) => {
    let index = undefined;

    state.cart.forEach((product, i) => {
      if (product.id === newProduct.data.id) {
        index = i;
      }
    });

    if (index !== undefined) {
      // увеличиваем кол-во товара
      commit(CART_UPDATE, {
        product: newProduct.data,
        count: newProduct.count,
        index: index,
      });
    } else {
      // добавляем товар
      commit(CART_ADD, newProduct);
    }
  },
  [CART_REMOVE]: ({ commit }, index) => {
    if (state.cart[index].prizeAmount > 1) {
      // уменьшаем кол-во товара
      commit(CART_UPDATE, {
        product: state.cart[index],
        count: -1,
        index: index,
      });
    } else {
      // удаляем товар
      commit(CART_REMOVE, index);
    }
  },
  [CART_CHECKOUT]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      CreateOrder(data)
        .then(() => {
          commit(CART_CLEAR);
          resolve();
        })
        .catch((err) => {
          commit(CART_CLEAR);
          reject(err);
        });
    });
  },
};

const mutations = {
  [CART_ADD]: (state, product) => {
    product.data.prizeAmount = product.count;
    state.cart.push(product.data);
  },
  [CART_REMOVE]: (state, index) => {
    state.cart.splice(index, 1);
  },
  [CART_UPDATE]: (state, data) => {
    data.product.prizeAmount = state.cart[data.index].prizeAmount + data.count;

    Vue.set(state.cart, data.index, data.product);
  },
  [CART_CLEAR]: (state) => {
    state.cart = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
