<template>
  <div id="app">
    <Header />
    <TaskManager v-if="isAuthenticated" />
    <router-view />
    <!-- <Footer /> -->
    <Modals />
  </div>
</template>

<script>
import Header from "@/layout/components/Header.vue";
// import Footer from "@/layout/components/Footer.vue";
import Modals from "@/components/Modals.vue";
import { mapGetters, mapActions } from "vuex";
import TaskManager from "@/components/TaskManager.vue";

export default {
  components: {
    Header,
    // Footer,
    Modals,
    TaskManager,
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  created() {
    const ua = window.navigator.userAgent;
    const isIE = /MSIE|Trident/.test(ua);

    if (isIE) {
      this.$nextTick(() => {
        this.$modal.show("message", {
          message:
            "Уважаемые коллеги! Кранос сломал Internet Explorer, для полного погружения в игру воспользуйтесь Google Chrome.",
        });
      });
    }

    if (this.isAuthenticated) {
      this.dataFetch();
    } else {
      this.authenticate();
    }
  },
  methods: {
    ...mapActions(["AUTH_FETCH", "USER_FETCH", "GAME_FETCH"]),
    authenticate() {
      const userName = location.search.split("userName=")[1];

      this.AUTH_FETCH(userName).then(() => {
        this.dataFetch();
      });
    },
    dataFetch() {
      this.USER_FETCH();
      this.GAME_FETCH();
    },
  },
};
</script>

<style lang="scss" src="@/styles/fonts.scss"></style>
<style lang="scss" src="@/styles/default.scss"></style>
<style lang="scss" src="@/styles/uni.scss"></style>
<style lang="scss" src="@/styles/button.scss"></style>
<style lang="scss" src="@/styles/controls.scss"></style>
<style lang="scss" src="@/styles/photo.scss"></style>
<style lang="scss" src="@/styles/navbar.scss"></style>
<style lang="scss" src="@/styles/modal.scss"></style>
<style lang="scss" src="@/styles/cabinet.scss"></style>
<style lang="scss" src="@/styles/profile.scss"></style>
