<template>
  <modal
    class="modal-overlay default"
    :name="name"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name">
      <div class="files-title">Файлы идеи</div>
      <div class="files-list">
        <div
          v-for="(item, index) in files"
          :key="index"
          class="files-list__item"
        >
          <div class="files-list__body">
            <template v-if="item.file || item.id">
              <div
                class="files-list__pic"
                :class="{ active: item.id }"
                @click="item.id ? onDownload(item.id) : null"
              ></div>
              <button
                class="files-list__delete"
                @click="onDelete(item, index)"
              ></button>
            </template>
            <button v-else class="files-list__upload">
              <input type="file" @change="onAttach(item, $event)" />
            </button>
          </div>
          <div class="files-list__foot">
            <div
              class="files-list__control"
              :class="{
                error: item.state === 'error' && item.file && !item.publicName,
              }"
            >
              <input
                v-if="!!item.id"
                :value="formatName(item.publicName)"
                placeholder="Введите название файла"
                type="text"
                :readonly="true"
              />
              <input
                v-else
                v-model="item.publicName"
                placeholder="Введите название файла"
                type="text"
              />
              <div class="error">Поле обязательно для заполнения</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-text">
        Назовите файл словом, описывающим его содержание. Название файла будет
        видно всем участникам при голосовании
      </div>
      <div class="modal-foot">
        <button
          :disabled="loading"
          @click="handleClick"
          class="button button-default button-modal"
          :class="{ loader: loading }"
        >
          Готово
          <Loading position="center" class="button-loader small" />
        </button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import Loading from "@/components/Loading.vue";
import { UploadFile, DeleteFile, DownloadFile } from "@/api/IdeaFiles";

import ModalLayout from "@/layout/ModalLayout.vue";

const filesDefault = [
  {
    file: "",
    publicName: "",
  },
  {
    file: "",
    publicName: "",
  },
  {
    file: "",
    publicName: "",
  },
  {
    file: "",
    publicName: "",
  },
  {
    file: "",
    publicName: "",
  },
];

export default {
  components: {
    ModalLayout,
    Loading,
  },
  data() {
    return {
      name: "uploadIdea",
      loading: false,
      ideaId: null,
      cb: null,
      files: [],
    };
  },
  computed: {
    filterNewFiles() {
      return this.files;
    },
  },
  methods: {
    beforeOpen(event) {
      this.files = JSON.parse(JSON.stringify(filesDefault));
      this.cb = event.params?.cb;
      this.ideaId = event.params?.ideaId;

      if (event.params?.files) {
        event.params.files.forEach((e, i) => {
          this.files[i] = Object.assign({}, e);
        });
      }

      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    async handleClick() {
      if (this.validate() && (await this.onSubmit())) {
        this.$modal.hide(this.name);
        if (typeof this.cb === "function") {
          this.cb(this.files.filter((e) => e.id));
        }
      }
    },
    validate() {
      let result = true;
      this.files.forEach((item, index) => {
        if (item.file && !item.publicName) {
          this.$set(this.files, index, Object.assign(item, { state: "error" }));
          result = false;
        } else {
          this.$set(this.files, index, Object.assign(item, { state: "" }));
        }
      });
      return result;
    },
    onDownload(fileId) {
      DownloadFile({ fileId }).then((resp) => {
        const { data } = resp;

        if (!data) {
          this.$message({
            type: "error",
            message: `Упс, что-то пошло не так`,
          });
        }

        let fileName = "_____";

        try {
          fileName = resp.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        const fileData = new Blob([data]);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // for IE
          window.navigator.msSaveOrOpenBlob(fileData, fileName);
        } else {
          // for Non-IE (chrome, firefox etc.)
          const url = URL.createObjectURL(fileData);
          const link = document.createElement("a");

          document.body.appendChild(link);
          link.style = "display: none";
          link.href = url;
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          link.remove();
        }
      });
    },
    onAttach(item, e) {
      if (item.id) {
        this.download(item.id);
      } else {
        const file = e.target.files[0];

        // 10мб
        if (file.size > 10 * 1000 * 1000) {
          this.$modal.show("messageLarge", {
            message: "Нельзя прикрепить файл размером более 10 мегабайт",
          });
        } else {
          item.file = file;
        }
      }
    },
    onDelete(item, index) {
      if (item.id) {
        DeleteFile({ fileId: item.id })
          .then(() => {
            this.files.splice(index, 1, {
              file: "",
              publicName: "",
            });
          })
          .catch((error) => {
            const { data } = error;

            this.$modal.show("messageLarge", {
              message: data ? data : "Упс, что-то пошло не так",
            });
          });
      } else {
        this.files.splice(index, 1, {
          file: "",
          publicName: "",
        });
      }
    },
    async onSubmit() {
      let submitted = true;
      this.loading = true;

      for (const file of this.files) {
        if (file.file) {
          try {
            const formData = new FormData();
            formData.append("file", file.file);
            const fileExtension = file.file.name.split(".").pop();

            const resp = await UploadFile(
              {
                ideaId: this.ideaId,
                publicName: file.publicName.trim() + "." + fileExtension,
              },
              formData
            );

            file.id = resp.data;
            delete file.file;
          } catch (error) {
            const { data } = error;

            this.$modal.show("messageLarge", {
              message: data ? data : "Упс, что-то пошло не так",
            });

            submitted = false;
          }
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 400);
      return submitted;
    },
    formatName(name) {
      if (name) {
        return name.split(".")[0];
      }
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.files-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 40px;
}
.files-list {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(1fr, 5);
  justify-content: center;
  gap: 10px;
  padding-bottom: 25px;
  &__item {
    width: 165px;
  }
  &__body {
    position: relative;
    border: 1px solid #5effff;
    border-radius: 19px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &__pic {
    width: 100%;
    height: 100%;
    background: #fff;
    background-image: url(~@/assets/img/icon/file.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    &.active {
      cursor: pointer;
    }
  }
  &__upload {
    @include reset-button;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.38 0C10.9136 0 0 10.9136 0 24.38c0 13.4664 10.9136 24.38 24.38 24.38 13.4664 0 24.38-10.9136 24.38-24.38C48.76 10.9136 37.8464 0 24.38 0Zm0 46.9988c-12.4978 0-22.6197-10.121-22.6197-22.6198 0-12.4988 10.1209-22.6197 22.6197-22.6197 12.4988 0 22.6198 10.1209 22.6198 22.6197 0 12.4988-10.121 22.6198-22.6198 22.6198Z' fill='%235EFFFF' fill-opacity='.6'/%3E%3Cpath d='M33.7967 23.4994h-8.5372v-8.5372c0-.5281-.352-.8802-.8801-.8802s-.8801.3521-.8801.8802v8.5372H14.962c-.528 0-.8801.352-.8801.8801s.3521.8801.8801.8801h8.5373v8.5373c0 .528.352.8801.8801.8801s.8801-.3521.8801-.8801v-8.5373h8.5372c.5281 0 .8802-.352.8802-.8801s-.4401-.8801-.8802-.8801Z' fill='%235EFFFF' fill-opacity='.6'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
  &__delete {
    @include reset-button;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23023936'/%3E%3Cpath d='M10.3272 6.6473c0-.318.258-.576.576-.576h8.0646a.5761.5761 0 0 1 0 1.152h-8.0646a.5761.5761 0 0 1-.576-.576ZM12.6313 13.1839a.576.576 0 0 0-1.1521 0v7.1129a.5762.5762 0 0 0 1.1521 0v-7.1129ZM17.8157 12.6079c.318 0 .576.258.576.576v7.1129a.5761.5761 0 0 1-1.1521 0v-7.1129c0-.318.258-.576.5761-.576ZM15.5116 13.1839a.5762.5762 0 0 0-1.1521 0v7.1129a.5761.5761 0 0 0 1.1521 0v-7.1129Z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.599 10.6795h-.288c-.7952 0-1.44-.6447-1.44-1.44 0-.7955.6448-1.4402 1.44-1.4402H21.56c.7954 0 1.4401.6447 1.4401 1.4401 0 .7954-.6447 1.4401-1.4401 1.4401h-.288v11.5207c0 .9544-.7737 1.7281-1.7281 1.7281h-9.2166c-.9544 0-1.7281-.7737-1.7281-1.7281V10.6795Zm13.2489-1.44a.288.288 0 0 1-.288.288H8.3111a.288.288 0 1 1 0-.5761h13.2488a.288.288 0 0 1 .288.288Zm-12.0967 1.44v11.5207c0 .3181.2579.5761.576.5761h9.2166c.3181 0 .576-.258.576-.5761V10.6795H9.7512Z' fill='%23fff'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  &__foot {
    margin-top: 7px;
    background: rgba(2, 57, 54, 0.35);
    border-radius: 3px;
  }
  &__control {
    position: relative;
    &.error {
      input {
        border-color: rgba(255, 0, 0, 0.35);
      }
      .error {
        display: block;
      }
    }
    .error {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      font-size: 12px;
      color: #ff0000;
      white-space: nowrap;
      text-align: center;
      width: 110%;
      transform: translateX(-50%);
      padding-top: 5px;
    }
    input {
      height: 30px;
      line-height: 30px;
      background: none;
      border: none;
      font-size: 18px;
      color: #fff;
      padding: 0 10px;
      width: 100%;
      font-family: $font;
      border: 1px solid transparent;
      padding-bottom: 4px;
      &::placeholder {
        font-size: 12px;
        line-height: 30px;
        color: #76a6a2;
      }
    }
  }
}

.modal-loading {
  position: absolute;
  top: 0;
  // bottom: 25px;
  // left: 50%;
  // transform: translateX(-50%);
}
</style>
