<template>
  <div class="task-manager" :class="{ show: isShow }">
    <div class="task-manager-panel">
      <div class="task-manager-panel__inner">
        <div class="task-manager-panel__head">
          <div class="task-manager-panel__title">Активности</div>
        </div>
        <div class="task-manager-panel__body">
          <div class="task-manager-panel__column left">
            <div class="task-manager-panel__cell">
              <div class="task-manager-grid">
                <div class="task-manager-grid__item">
                  <div class="task-manager-panel__switcher">
                    <div class="uni-switcher" :class="{ active: isActiveTask }">
                      <button
                        class="uni-switcher__button"
                        @click="isActiveTask = !isActiveTask"
                      >
                        Активные задания
                      </button>
                      <button
                        class="uni-switcher__button"
                        @click="isActiveTask = !isActiveTask"
                      >
                        Завершенные задания
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="
                      (isActiveTask && tasksActive.length === 0) ||
                      (!isActiveTask && tasksArchive.length === 0)
                    "
                    class="task-manager-empty"
                  >
                    <template v-if="isActiveTask"
                      >Пока нет актиных заданий</template
                    >
                    <template v-else>Пока нет завершенных заданий</template>
                  </div>
                  <div v-else class="task-manager-list">
                    <div class="task-manager-list__inner">
                      <div
                        v-for="(item, index) in isActiveTask
                          ? tasksActive
                          : tasksArchive"
                        :key="index"
                        class="task-manager-item"
                      >
                        <div class="task-manager-table">
                          <table>
                            <tr>
                              <td>Задание:</td>
                              <td>
                                {{ item.title }}
                              </td>
                            </tr>
                            <tr v-if="item.dateToComplete">
                              <td>Срок:</td>
                              <td>
                                до
                                {{
                                  normalizeDate(item.dateToComplete)
                                    | formatDate
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Награда:</td>
                              <td>
                                <div class="uni-coins">
                                  <img
                                    src="@/assets/img/pic2/coin.svg"
                                    width="15"
                                    height="15"
                                    alt=""
                                  />
                                  <template v-if="isActiveTask">
                                    {{ item.minCoins }} - {{ item.maxCoins }}
                                  </template>
                                  <template v-else>
                                    {{ item.receivedCoins }}
                                  </template>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Статус:</td>
                              <td>{{ item.state | functionalTaskStatus }}</td>
                            </tr>
                          </table>
                        </div>
                        <div
                          v-if="item.state < 3"
                          class="task-manager-item__foot"
                        >
                          <button
                            class="task-manager-item__button"
                            @click="openQuest(item)"
                          >
                            Открыть задание
                          </button>
                        </div>
                      </div>
                      <!-- <div class="task-manager-item">
                        <div class="task-manager-table">
                          <table>
                            <tr>
                              <td>Задание:</td>
                              <td>
                                Вам необходимо держать показатель СВР менее 0,6
                              </td>
                            </tr>
                            <tr>
                              <td>Срок:</td>
                              <td>до 17.03.2022</td>
                            </tr>
                            <tr>
                              <td>Награда:</td>
                              <td>
                                <div class="uni-coins">
                                  <img
                                    src="@/assets/img/pic2/coin.svg"
                                    width="15"
                                    height="15"
                                    alt=""
                                  />1-4
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Статус:</td>
                              <td>В работе</td>
                            </tr>
                          </table>
                        </div>
                        <div class="task-manager-item__foot">
                          <button class="task-manager-item__button">
                            Открыть задание
                          </button>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <!-- <div class="task-manager-list">
                    <div class="task-manager-list__inner">
                      <div v-for="(item, index) in tasksArchive" :key="index" class="task-manager-item">
                        <div class="task-manager-table">
                          <table>
                            <tr>
                              <td>Задание:</td>
                              <td>
                                Вам необходимо держать показатель СВР менее 0,6
                              </td>
                            </tr>
                            <tr>
                              <td>Срок:</td>
                              <td>до 17.03.2022</td>
                            </tr>
                            <tr>
                              <td>Награда:</td>
                              <td>
                                <div class="uni-coins">
                                  <img
                                    src="@/assets/img/pic2/coin.svg"
                                    width="15"
                                    height="15"
                                    alt=""
                                  />1-4
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Статус:</td>
                              <td>В работе</td>
                            </tr>
                          </table>
                        </div>
                        <div class="task-manager-item__foot">
                          <button class="task-manager-item__button">
                            Открыть задание
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="task-manager-grid__item">
                  <div class="task-manager-title">
                    Осталось взять заданий на неделе:
                  </div>
                  <div class="task-manager-item">
                    <div class="task-manager-table">
                      <table>
                        <tr>
                          <td>Количество:</td>
                          <td>
                            {{
                              getProfileCurrentIsland.remainingQuestsThisWeek
                            }}
                            шт.
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div
                      v-if="getCurrentIslandLink"
                      class="task-manager-item__foot"
                    >
                      <router-link
                        :to="getCurrentIslandLink"
                        class="task-manager-item__button"
                        @click.native="isShow = false"
                        >Перейти и взять</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="task-manager-panel__column right">
            <div class="task-manager-panel__cell">
              <div class="task-manager-title">Командная цель:</div>
              <div class="task-manager-item">
                <div class="task-manager-table">
                  <table v-if="getProfileTeamQuest">
                    <tr>
                      <td>Цель:</td>
                      <!-- <td>—</td> -->
                      <td>{{ getProfileTeamQuest.title }}</td>
                    </tr>
                    <tr>
                      <td>Срок проведения:</td>
                      <!-- <td>Сейчас не проводится</td> -->
                      <td>
                        {{ getProfileTeamQuest.startDate | formatDate2 }} -
                        {{ getProfileTeamQuest.endDate | formatDate2 }}
                      </td>
                    </tr>
                    <tr>
                      <td>Награда:</td>
                      <td>
                        <div
                          v-if="getProfileTeamQuest.targets"
                          class="uni-coins"
                        >
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />{{ teamQuestScore[0].coins | priceFilter }} -
                          {{
                            teamQuestScore[teamQuestScore.length - 1].coins
                              | priceFilter
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Статус:</td>
                      <td>{{ getProfileTeamQuest.state | formatTeamQuest }}</td>
                    </tr>
                  </table>
                  <table v-else>
                    <tr>
                      <td>Цель:</td>
                      <td>—</td>
                    </tr>
                    <tr>
                      <td>Срок проведения:</td>
                      <td>Сейчас не проводится</td>
                    </tr>
                    <tr>
                      <td>Награда:</td>
                      <td>—</td>
                    </tr>
                    <tr>
                      <td>Статус:</td>
                      <td>—</td>
                    </tr>
                  </table>
                </div>
                <div class="task-manager-item__foot">
                  <router-link
                    :to="{ name: 'teamGoal' }"
                    class="task-manager-item__button"
                    @click.native="isShow = false"
                  >
                    Перейти к цели
                  </router-link>
                </div>
              </div>
            </div>
            <div class="task-manager-panel__cell">
              <div class="task-manager-title">Идеи</div>
              <div class="task-manager-item">
                <div class="task-manager-table">
                  <table>
                    <tr>
                      <td>Этап:</td>
                      <td>
                        <template v-if="getSeason.currentState">{{
                          getSeason.currentState | formatSeasonIdeas
                        }}</template>
                        <template v-else>—</template>
                      </td>
                    </tr>
                    <tr>
                      <td>Срок проведения:</td>
                      <td>{{ getSeasonDate }}</td>
                    </tr>
                    <tr>
                      <td>Награда:</td>
                      <td>
                        <template v-if="getSeason.maxReward">
                          <div class="uni-coins">
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />{{ getSeason.maxReward }}
                          </div>
                        </template>
                        <template v-else>—</template>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="task-manager-item__foot">
                  <router-link
                    :to="{ name: 'ideas' }"
                    class="task-manager-item__button"
                    @click.native="isShow = false"
                    >На страницу идей</router-link
                  >
                </div>
              </div>
            </div>
            <div class="task-manager-panel__cell">
              <div class="task-manager-title">Поединок</div>
              <div class="task-manager-item">
                <div class="task-manager-table">
                  <table v-if="getBattle.startDay">
                    <tr>
                      <td>Этап:</td>
                      <td v-if="getBattle.state === 1">
                        Сбор заявок на участие
                      </td>
                      <td v-else-if="getBattle.state === 2">Поединок</td>
                      <td v-else>—</td>
                    </tr>
                    <tr>
                      <td>Срок проведения:</td>
                      <td>
                        {{ getBattle.startDay | formatDate2 }} -
                        {{
                          normalizeDateEndBattle(getBattle.startDay)
                            | formatDate2
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Награда:</td>
                      <td>
                        <div class="uni-coins">
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />{{ getBattle.looserReward }} -
                          {{ getBattle.winnerReward }}
                        </div>
                      </td>
                    </tr>
                  </table>
                  <table v-else>
                    <tr>
                      <td>Этап:</td>
                      <td>Сейчас не проводится</td>
                      <!-- <td>10.03.2022 - 17.03.2022</td> -->
                    </tr>
                    <tr>
                      <td>Срок проведения:</td>
                      <td>—</td>
                      <!-- <td>10.03.2022 - 17.03.2022</td> -->
                    </tr>
                    <tr>
                      <td>Награда:</td>
                      <td>
                        —
                        <!-- <div class="uni-coins">
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0 - 3 000
                        </div> -->
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="task-manager-item__foot">
                  <!-- <button class="task-manager-item__button">
                    На страницу батлов
                  </button> -->
                  <router-link
                    :to="{ name: 'battles' }"
                    class="task-manager-item__button"
                    @click.native="isShow = false"
                    >На страницу "Поединок"</router-link
                  >
                  <!-- <button class="task-manager-item__button" disabled>
                    Начать бой
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="task-manager-button"
      @click="
        isShow = !isShow;
        fetchData();
      "
    >
      Статус задач
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { islandList } from "@/data/game";
import { GetQuestByID } from "@/api/PlayerQuests";
import { addDay } from "@/utils";

import { GetPlayerQuestsStates } from "@/api/PlayerQuests";

export default {
  computed: {
    ...mapGetters([
      "getGame",
      "getProfile",
      "getProfileCurrentIsland",
      "getTeam",
      "getProfileTeamQuest",
      "getSeason",
      "getBattle",
    ]),
    getCurrentIslandLink() {
      const currentIsland = this.getProfileCurrentIsland.islandIdx;
      const data = {};

      if (currentIsland !== undefined) {
        islandList.forEach((island, index) => {
          if (index === currentIsland) {
            data.name = island.name;
          }
        });
      }

      return data.name ? `/island/${data.name}` : null;
    },
    tasksActive() {
      return this.tasks.filter((e) => e.state <= 2);
    },
    tasksArchive() {
      return this.tasks.filter((e) => e.state >= 3);
    },
    teamQuestScore() {
      return this.getProfileTeamQuest?.targets
        ?.map((e) => e)
        .sort((a, b) => {
          return a.targetScore - b.targetScore;
        });
    },
    getSeasonDate() {
      if (this.getSeason.currentState === 0) {
        return (
          "до " + this.$options.filters.formatDate2(this.getSeason.filingDate)
        );
      } else if (this.getSeason.currentState === 1) {
        return (
          "до " +
          this.$options.filters.formatDate2(this.getSeason.processingDate)
        );
      } else if (this.getSeason.currentState === 2) {
        return (
          "до " + this.$options.filters.formatDate2(this.getSeason.votingDate)
        );
      } else if (this.getSeason.currentState === 3) {
        return (
          "до " + this.$options.filters.formatDate2(this.getSeason.finalDate)
        );
      } else if (this.getSeason.currentState === 4) {
        return "до начала следующего сезона";
      }
      return "Сейчас не проводится";
    },
  },
  watch: {
    $route() {
      this.isShow = false;
    },
  },
  data() {
    return {
      isShow: false,
      isActiveTask: true,
      tasks: [],
    };
  },
  created() {
    this.fetchData();
    this.IDEA_SEASON_FETCH();
    this.BATTLE_FETCH();
  },
  methods: {
    ...mapActions(["IDEA_SEASON_FETCH", "BATTLE_FETCH"]),
    fetchData() {
      GetPlayerQuestsStates().then((resp) => {
        this.tasks = resp.data;
      });
    },
    normalizeDate(date) {
      return addDay(date, -1);
    },
    normalizeDateEndBattle(date) {
      return addDay(date, 13);
    },
    openQuest(task) {
      // if (task.state >= 2) {
      //   return;
      // }

      GetQuestByID({ questId: task.id }).then((resp) => {
        const questType = resp.data.questType;

        switch (questType) {
          case 0: // Kpi
            this.$modal.show("kpi", {
              data: resp.data,
              type: "kpi",
              buttonName: "Ок",
            });
            break;
          case 1: // Test
            this.$modal.show("test", { questId: resp.data.id });
            break;
          case 2: // Question
            this.$modal.show("question", { questId: resp.data.id });
            break;
          case 3: // OffGame
            this.$modal.show("kpi", {
              data: resp.data,
              type: "offGame",
              buttonName: "Ок",
            });
            break;
          case 4: // Business
            this.$modal.show("business", { data: resp.data, buttonName: "Ок" });
            break;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-manager {
  position: fixed;
  top: $headerHeight;
  margin-top: -50px;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, calc(-100vh + #{$headerHeight}), 0);
  transition: 0.6s;
  &.show {
    transform: translate3d(-50%, 0, 0);
    .task-manager-button {
      transform: none;
      padding-bottom: 0;
      &::before {
        transform: none;
      }
    }
  }
  &-panel {
    background-image: url(~@/assets/img/pics/task-bg.svg);
    background-repeat: no-repeat;
    background-position: 50% 100%;
    width: 1154px;
    height: calc(100vh - #{$headerHeight});
    padding: 60px 60px;
    padding-top: 55px;
    padding-bottom: 95px;
    &__inner {
      display: grid;
      grid-template-rows: auto 1fr;
      height: 100%;
      gap: 10px;
    }
    &__head {
      text-align: center;
    }
    &__switcher {
      padding: 10px 45px;
    }
    &__title {
      font-size: 40px;
      color: #50ffeb;
    }
    &__body {
      overflow-y: auto;
      @include scrollbar;
      padding-left: 15px;
      padding-right: 15px;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      height: 100%;
      gap: 15px;
    }
    &__column {
      display: grid;
      gap: 10px;
      &.right {
        grid-template-rows: auto auto auto;
      }
    }
    &__cell {
      background: rgba(0, 255, 254, 0.05);
    }
  }
  &-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 38px;
  }
  &-list {
    position: relative;
    // padding-right: 15px;
    margin-right: 10px;
    margin-bottom: -20px;
    .task-manager-item {
      margin-right: 10px;
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 10px);
      height: 70px;
      content: "";
      background: linear-gradient(180deg, rgba(3, 33, 42, 0) 0%, #031720 100%);
      pointer-events: none;
    }
    &__inner {
      min-height: 420px;
      height: calc(100vh - #{$headerHeight} - 450px);
      overflow: auto;
      @include scrollbar;
    }
  }
  &-empty {
    background: rgba(0, 255, 254, 0.03);
    border-radius: 2px;
    height: calc(100% - 75px);
    position: relative;
    z-index: 1;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: rgba(173, 229, 217, 0.7);
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
  }
  &-table {
    table {
      width: 100%;
      tr {
        td {
          padding: 0px 18px;
          &:nth-child(1) {
            font-size: 14px;
            color: #ade5d9;
          }
          &:nth-child(2) {
            font-size: 16px;
            text-align: right;
          }
          vertical-align: top;
        }
      }
    }
  }
  &-grid {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr auto;
    height: 100%;
  }
  &-item {
    background: rgba(0, 255, 254, 0.05);
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px 0;
    margin-bottom: 20px;
    &__foot {
      text-align: center;
      padding: 5px 20px;
      display: flex;
      justify-content: center;
      &.between {
        justify-content: space-between;
      }
    }
    &__button {
      @include reset-button;
      font-weight: 700;
      font-size: 18px;
      text-decoration: underline;
      color: #50ffeb;
      &:hover {
        text-decoration: none;
      }
      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
  &-button {
    @include reset-button;
    background-image: url(~@/assets/img/pics/task-button.svg);
    background-repeat: no-repeat;
    width: 429px;
    height: 107px;
    font-weight: 700;
    font-size: 32px;
    position: relative;
    display: block;
    margin: 0 auto;
    margin-top: -90px;
    transform: translateY(115px);
    padding-bottom: 10px;
    transition: 0.8s;
    &::before {
      position: absolute;
      top: 28px;
      left: 50%;
      margin-left: -9px;
      // transform: translateX(-50%);
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5809 9 18 7.4469 9.0002 0 0 7.4469 1.4191 9l7.581-6.2728L16.581 9Z' fill='%2350FFEB'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      width: 18px;
      height: 9px;
      transform: rotate(180deg) translateY(-45px);
    }
  }
}
</style>
