<template>
  <div class="modals">
    <ModalUploadIdea />
    <ModalMessage />
    <ModalMessageLarge />
    <ModalTutorial />
    <ModalPhoto />
    <ModalUploadPhoto />
    <ModalLegend />
    <ModalMechanic />
    <ModalGratitude />
    <ModalStats />
    <ModalStatsRating />
    <ModalConfirm />
    <ModalTaskQuestion />
    <ModalTaskTest />
    <ModalTaskKPI />
    <ModalTaskBusiness />
  </div>
</template>

<script>
import ModalMessage from "@/components/ModalMessage.vue";
import ModalMessageLarge from "@/components/ModalMessageLarge.vue";
import ModalTutorial from "@/components/ModalTutorial.vue";
import ModalPhoto from "@/components/ModalPhoto.vue";
import ModalUploadPhoto from "@/components/ModalUploadPhoto.vue";
import ModalUploadIdea from "@/components/ModalUploadIdea.vue";
import ModalLegend from "@/components/ModalLegend.vue";
import ModalMechanic from "@/components/ModalMechanic.vue";
import ModalGratitude from "@/components/ModalGratitude.vue";
import ModalStats from "@/components/ModalStats.vue";
import ModalStatsRating from "@/components/ModalStatsRating.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import ModalTaskKPI from "@/components/ModalTaskKPI.vue";
import ModalTaskBusiness from "@/components/ModalTaskBusiness.vue";
import ModalTaskQuestion from "@/components/ModalTaskQuestion.vue";
import ModalTaskTest from "@/components/ModalTaskTest.vue";

export default {
  components: {
    ModalMessage,
    ModalMessageLarge,
    ModalTutorial,
    ModalPhoto,
    ModalUploadPhoto,
    ModalUploadIdea,
    ModalLegend,
    ModalMechanic,
    ModalGratitude,
    ModalStats,
    ModalStatsRating,
    ModalConfirm,
    ModalTaskKPI,
    ModalTaskBusiness,
    ModalTaskQuestion,
    ModalTaskTest,
},
};
</script>
