<template>
  <modal
    class="modal-overlay default"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="default" class="modal-skin_type_long">
      <div class="stats">
        <div class="stats__head">
          <div class="stats__title">{{ title }}</div>
          <div class="stats__coin">
            {{ selfValue | priceFilter }}
            <span v-if="filterActive">%</span
            ><img
              v-else
              src="@/assets/img/pic2/coin.svg"
              width="15"
              height="15"
              alt=""
            />
          </div>
        </div>
        <div v-if="filterActive" class="stats__filter">
          <div class="stats-filter">
            <button
              @click="onChangeFilter(0)"
              :class="{ active: filterType === 0 }"
              class="stats-filter__button"
            >
              Неделя
            </button>
            <button
              @click="onChangeFilter(1)"
              :class="{ active: filterType === 1 }"
              class="stats-filter__button"
            >
              Все время
            </button>
          </div>
        </div>
        <div class="stats__body">
          <div class="stats__main">
            <div class="stats__graphic">
              <Chart
                v-if="!loading && weeks.length"
                :axisX="axisX"
                :axisY="axisY"
              />
            </div>
            <div class="stats__foot">
              <div v-if="!loading" class="stats-list">
                <div class="stats-list__body">
                  <div
                    v-for="(item, index) in normalizeWeeks"
                    :key="index"
                    class="stats-list__item"
                  >
                    <div class="stats-list__week">{{ item.index }} неделя</div>
                    <div class="stats-list__line"></div>
                    <div class="stats-list__date">
                      {{ item.index | formatTimeRange }}
                    </div>
                    <div class="stats-list__line"></div>
                    <div class="stats-list__value">
                      {{ item.value }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="weeks.length > numCollapsedWeeks"
                  class="stats-list__foot"
                >
                  <button
                    @click="isCollapsedWeeks = !isCollapsedWeeks"
                    class="stats-list__button"
                  >
                    {{ isCollapsedWeeks ? "Свернуть" : "Раскрыть больше" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="stats__sidebar">
            <div
              ref="usersList"
              class="stats-users"
              :class="{ 'stats-users_dots': showDots }"
            >
              <div v-if="loading" class="stats-users__inner">
                <div class="skeleton" v-for="(item, index) in 5" :key="index">
                  <div class="skeleton__circle"></div>
                  <div
                    class="skeleton__line h17 color1"
                    style="top: 15px; left: 125px; width: 160px"
                  ></div>
                  <div
                    class="skeleton__line h17 color2"
                    style="top: 35px; left: 125px; width: 180px"
                  ></div>
                  <div
                    class="skeleton__line h21 color3"
                    style="top: 60px; left: 125px; width: 250px"
                  ></div>
                </div>
              </div>
              <div v-else class="stats-users__inner">
                <div
                  v-for="(item, index) in normalizeUsers"
                  :key="index"
                  class="stats-users__item"
                  :class="{ 'stats-users__item_self': item.self }"
                >
                  <div class="stats-users__pic">
                    <img
                      v-if="item.photo"
                      :src="baseUrl + item.photo"
                      width="68"
                      height="68"
                      alt=""
                    />
                  </div>
                  <div class="stats-users__content">
                    <div class="stats-users__group">
                      <div
                        class="stats-users__text"
                        :class="{ 'stats-users__text_type_captain': item.self }"
                      >
                        {{ item.position }} место
                        <div>в рейтинге</div>
                      </div>
                      <div class="stats-users__text">
                        {{ item.value }}{{ unit ? unit : "" }}
                        <div>{{ unit ? "" : COINS }}</div>
                      </div>
                    </div>
                    <div class="stats-users__name">
                      {{ item.fio }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

import Chart from "@/components/Chart.vue";

import { getBaseURL, mathRoundToDecimal } from "@/utils";
import { GetPlayerCoinHistory, GetPlayerKPIHistory } from "@/api/Player";

export default {
  components: {
    ModalLayout,
    Chart,
  },
  computed: {
    baseUrl() {
      return getBaseURL();
    },
    normalizeWeeks() {
      if (this.isCollapsedWeeks) {
        return this.weeks;
      } else {
        return this.weeks.slice(0, this.numCollapsedWeeks);
      }
    },
    axisX() {
      return this.weeks
        .map((e) => {
          return e.index;
        })
        .reverse();
    },
    axisY() {
      return this.weeks
        .map((e) => {
          return e.value;
        })
        .reverse();
    },
    normalizeUsers() {
      return this.filterType === 1 ? this.usersTotal : this.users;
    },
    selfValue() {
      if (!this.normalizeUsers.length) return "";

      const users = this.normalizeUsers;
      const self = users.find((e) => e.self);

      return self ? self.value : "";
    },
    showDots() {
      if (!this.normalizeUsers.length) return false;
      if (this.normalizeUsers.length <= 5) return false;

      const users = this.normalizeUsers;
      const self = users.find((e) => e.self);

      if (self.position > 7) return true;

      return false;
    },
  },
  data() {
    return {
      name: "stats",
      loading: false,
      users: [],
      usersTotal: [],
      weeks: [],
      // weeks: [
      //   {
      //     index: 5,
      //     date: "с 20.08 по 26.08",
      //     value: 12
      //   },
      //   {
      //     index: 4,
      //     date: "с 13.08 по 19.08",
      //     value: 46
      //   },
      //   {
      //     index: 3,
      //     date: "с 06.08 по 12.08",
      //     value: 11
      //   },
      //   {
      //     index: 2,
      //     date: "с 20.08 по 26.08",
      //     value: 25
      //   },
      //   {
      //     index: 1,
      //     date: "с 20.08 по 26.08",
      //     value: 5
      //   }
      // ],
      isCollapsedWeeks: false,
      numCollapsedWeeks: 4,

      title: "",
      value: null,
      unit: "",

      filterActive: false,
      filterType: 0,

      tempCount: 1000,
      tempCount2: 14,
    };
  },
  methods: {
    async beforeOpen(event) {
      const { filter, data } = event.params;
      const { userId, kpiId, title, value, users, usersTotal, userCoin, unit } =
        data;
      this.loading = true;

      this.filterActive = filter ? true : false;
      this.filterType = 0;

      this.title = title;
      this.value = value;

      if (userCoin) {
        this.users = userCoin;
      } else {
        this.users = users || [];
        this.usersTotal = usersTotal || [];
      }

      this.unit = unit;

      // this.users = await this.fetchUsers();

      if (kpiId) {
        this.weeks = await this.fetchKPIHistory(userId, kpiId);
      } else {
        this.weeks = await this.fetchCoinHistory(userId);
      }

      this.$nextTick(() => {
        this.loading = false;

        setTimeout(() => {
          let index = 0;
          this.users.forEach((e, i) => {
            if (e.self) {
              index = i;
            }
          });
          this.setScrollPosition(index);
        }, 0);
      });

      // console.log(data);

      // setTimeout(() => {
      //   this.setScrollPosition(899);
      // }, 3000);
    },
    async fetchCoinHistory(userId) {
      const resp = await GetPlayerCoinHistory({
        playerId: userId,
      });
      return resp.data
        .map((e) => {
          return {
            index: e.week + 1,
            value: e.amount,
          };
        })
        .sort((a, b) => b.index - a.index);
    },
    async fetchKPIHistory(userId, kpiId) {
      const resp = await GetPlayerKPIHistory({
        playerId: userId,
        kpi: kpiId,
      });
      return resp.data
        .map((e) => {
          return {
            index: e.week,
            value: mathRoundToDecimal(e.absoluteValue),
          };
        })
        .sort((a, b) => b.index - a.index);
    },
    async fetchUsers() {
      this.loading = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.loading = false;
          resolve([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        }, 500);
      });
    },
    setScrollPosition(index) {
      const list = this.$refs.usersList;
      const items = list.querySelectorAll(".stats-users__item");

      items.forEach((element, i) => {
        if (i === index) {
          element.scrollIntoView({ block: "center" });
        }
      });
    },
    onChangeFilter(type) {
      this.filterType = type;

      this.$nextTick(() => {
        let index = 0;
        this.normalizeUsers.forEach((e, i) => {
          if (e.self) {
            index = i;
          }
        });
        this.setScrollPosition(index);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stats {
  text-align: left;
  margin-top: -30px;
  margin-left: -80px;
  margin-right: -80px;
  margin-bottom: 0;
  &__head {
    text-align: center;
  }
  &__title {
    font-size: 25px;
    color: #5cffed;
  }
  &__coin {
    margin-top: 5px;
    font-size: 25px;
    color: #5cffed;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: -3px;
      margin-left: 10px;
      margin-right: -26px;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 655px auto;
    margin-top: 25px;
  }
  &__graphic {
    margin-top: -58px;
    margin-left: -45px;
    margin-right: -75px;
    margin-bottom: -42px;
    height: 400px;
  }
  &__foot {
    margin-top: 40px;
  }
  &__sidebar {
    margin-left: 50px;
    position: relative;
    min-height: 500px;
  }
  &-filter {
    text-align: right;
    &__button {
      @include reset-button;
      margin-left: 20px;
      &.active {
        color: #5cffed;
        text-decoration: underline;
        pointer-events: none;
      }
      font-size: 17px;

      color: #457973;
    }
  }
  &-list {
    position: relative;
    margin-top: 63px;
    &__body {
      padding-right: 10px;
      overflow: auto;
      height: 132px;
      margin-right: -5px;
      @include scrollbar;
    }
    &__item {
      display: grid;
      grid-template-columns: 87px 50px 140px 1fr auto;
      align-items: center;
      & + .stats-list__item {
        margin-top: 15px;
      }
    }
    &__week {
      font-size: 17px;
      color: #5cffed;
    }
    &__line {
      width: 100%;
      height: 1px;
      background-color: #305350;
      margin-top: 8px;
    }
    &__date {
      font-size: 16px;
      color: #35978c;
      text-align: center;
    }
    &__value {
      text-align: right;
      font-family: $fontRafale;
      font-size: 16px;
      padding-left: 25px;
    }
    &__foot {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 25px;
    }
    &__button {
      @include reset-button;
      font-size: 16px;
      color: #73fff4;
      text-decoration: underline;
      margin-bottom: -20px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-users {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    @include scrollbar;
    &_dots {
      .stats-users__item:nth-child(5) {
        &::after {
          position: absolute;
          top: 100%;
          content: "...";
          margin-top: -15px;
          left: 0;
          color: #5cffed;
          font-size: 22px;
          width: 68px;
          text-align: center;
        }
      }
    }
    &__inner {
      display: grid;
      gap: 0px;
    }
    &__item {
      display: grid;
      grid-template-columns: 100px auto;
      height: 100px;
      position: relative;
      padding-left: 25px;
      padding-top: 15px;
      &_self {
        background: rgba(0, 255, 254, 0.15);
      }
    }
    &__pic {
      width: 68px;
      height: 68px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 68px;
      background-image: url(~@/assets/img/pic2/avatar-default-small.png);
      background-size: contain;

      img {
        object-fit: cover;
        border-radius: 100px;
      }
    }
    &__group {
      display: grid;
      grid-template-columns: 110px auto;
    }
    &__text {
      font-size: 15px;
      color: #5cffed;
      position: relative;
      &_type_captain {
        &::before {
          position: absolute;
          top: 0;
          right: 100%;
          width: 24px;
          height: 20px;
          content: "";
          margin-top: -1px;
          margin-right: 4px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_dd)'%3E%3Cpath d='M12 4.6517l1.4616 3.7486.149.382H18.41l-3.4513 2.0897-.4315.2613.1832.4699 1.3787 3.5359-3.7783-2.2876L12 12.6633l-.3108.1882-3.7783 2.2876 1.3787-3.5359.1832-.4699-.4315-.2613L5.59 8.7824h4.7994l.149-.382L12 4.6516z' stroke='%2352FFEC' stroke-width='1.2' shape-rendering='crispEdges'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_dd' x='.4404' y='0' width='23.119' height='19.5676' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='1.5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.638194 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow' result='effect2_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
        }
      }
      & > div {
        color: #87aaa6;
      }
    }
    &__name {
      font-family: $fontRafale;
      font-size: 20px;
      margin-top: 5px;
    }
  }
}

.skeleton {
  position: relative;
  height: 100px;
  animation-timing-function: steps(20, end);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-name: blink;
  &__circle {
    width: 68px;
    height: 68px;
    border-radius: 68px;
    background-color: #fff;
    margin-left: 25px;
    margin-top: 15px;
  }
  &__line {
    position: absolute;
    &.h17 {
      height: 14px;
    }
    &.h21 {
      height: 17px;
    }
    &.color1 {
      background-color: #5cffed;
    }
    &.color2 {
      background-color: #87aaa6;
    }
    &.color3 {
      background-color: #fff;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>
