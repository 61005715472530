import {
  GRATITUDES_FETCH,
  GRATITUDES_SUCCESS,
  GRATITUDES_ERROR,
  GRATITUDES_VALUATIONS_FETCH,
  GRATITUDES_VALUATIONS_SUCCESS,
  GRATITUDES_VALUATIONS_ERROR,
} from "../actions/gratitudes";
import { GetAllGratitudes, GetAvailableGratitudeCoins } from "@/api/Gratitudes";

const state = {
  gratitudes: [],
  valuations: [],
  count: 0,
  status: "",
  statusValuations: "",
};

const getters = {
  getGratitudes: (state) => state.gratitudes,
  getGratitudesStatus: (state) => state.status,
  getGratitudesValuations: (state) => state.valuations,
  getGratitudesValuationsStatus: (state) => state.statusValuations,
};

const actions = {
  [GRATITUDES_FETCH]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(GRATITUDES_FETCH);
      GetAllGratitudes({ pageSize: 10000, pageIndex: 0 })
        .then((resp) => {
          commit(GRATITUDES_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(GRATITUDES_ERROR);
        });
    });
  },
  [GRATITUDES_VALUATIONS_FETCH]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(GRATITUDES_VALUATIONS_FETCH);
      GetAvailableGratitudeCoins()
        .then((resp) => {
          commit(GRATITUDES_VALUATIONS_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(GRATITUDES_VALUATIONS_ERROR);
        });
    });
  },
};

const mutations = {
  [GRATITUDES_FETCH]: (state) => {
    state.status = "loading";
  },
  [GRATITUDES_SUCCESS]: (state, data) => {
    state.status = "success";
    state.gratitudes = data.gratitudes;
    state.count = data.gratitudes.overallCount;
  },
  [GRATITUDES_ERROR]: (state) => {
    state.status = "error";
  },
  [GRATITUDES_VALUATIONS_FETCH]: (state) => {
    state.statusValuations = "loading";
  },
  [GRATITUDES_VALUATIONS_SUCCESS]: (state, data) => {
    let valuations = [];

    data.forEach((gratitude) => {
      gratitude.availableValuations.forEach((valuation) => {
        valuations.push(valuation);
      });
    });

    state.statusValuations = "success";
    state.valuations = valuations;
  },
  [GRATITUDES_VALUATIONS_ERROR]: (state) => {
    state.statusValuations = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
